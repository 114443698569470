<template>
  <b-field :label="label" :message="message" :type="type">
    <div class="html-editor" :class="type" :style="{ 'min-height': minHeight }">
      <editor-menu-bar v-if="showMenuBar" v-slot="{ commands, isActive, focused }" :editor="editor">
        <div class="menubar" :class="{ 'is-focused': focused }">
          <button class="button is-text is-small" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
            <icon name="bold" />
          </button>

          <button class="button is-text is-small" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
            <icon name="italic" />
          </button>

          <button
            class="button is-text is-small"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <icon name="underline" />
          </button>

          <button
            class="button is-text is-small"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <icon name="paragraph" />
          </button>

          <button class="button is-text is-small" @click="commands.undo">
            <icon name="undo" />
          </button>

          <button class="button is-text is-small" @click="commands.redo">
            <icon name="redo" />
          </button>
        </div>
      </editor-menu-bar>
      <editor-content :editor="editor" />
    </div>
    <template v-if="$slots.message" #message><slot name="message"></slot></template>
  </b-field>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, Italic, Link, Underline, History, Placeholder } from 'tiptap-extensions'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: {
    label: String,
    message: String,
    type: [Object, String],
    editable: Boolean,
    input: String,
    showMenuBar: Boolean,
    minHeight: {
      type: String,
      default: '25vh',
    },
  },
  data() {
    return {
      editor: new Editor({
        editable: this.editable,
        extensions: [
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.$t('writeText'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.$emit('update:input', getHTML())
        },
      }),
    }
  },
  mounted() {
    // Popula o editor de texto
    this.editor.setContent(this.input)
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  methods: {},
}
</script>

<style>
.html-editor.is-danger {
  border-color: #ff6b6b;
}
</style>
