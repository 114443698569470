<template>
  <ApplicationResearchers v-if="applicationProfile == 'researchers'"></ApplicationResearchers>
  <ApplicationResearchersDL57 v-else-if="applicationProfile == 'researchers-dl57'"></ApplicationResearchersDL57>
  <ApplicationScholarships v-else-if="applicationProfile == 'scholarships'"></ApplicationScholarships>
  <ApplicationStaff v-else-if="applicationProfile == 'staff'"></ApplicationStaff>
  <ApplicationTeacher v-else></ApplicationTeacher>
</template>

<script>
import ApplicationTeacher from '@/components/application/ApplicationTeacher'
import ApplicationResearchers from '@/components/application/ApplicationResearchers'
import ApplicationResearchersDL57 from '@/components/application/ApplicationResearchersDL57'
import ApplicationScholarships from '@/components/application/ApplicationScholarships'
import ApplicationStaff from '@/components/application/ApplicationStaff'
import UtilitiesService from '@/services/UtilitiesService'

// const PROCEDURE_TYPES = {
//   'P053-': 'teachers',
//   'IT074-': 'researchers',
//   'P046-': 'researchers',
//   'IT057-': 'researchers-dl57',
//   'UIDP-': 'scholarships',
//   'P048-': 'staff',
// }

export default {
  components: {
    ApplicationTeacher,
    ApplicationResearchers,
    ApplicationResearchersDL57,
    ApplicationScholarships,
    ApplicationStaff,
  },
  data() {
    return {
      procedureTypes: {},
    }
  },
  computed: {
    applicationProfile() {
      for (let values of Object.values(this.procedureTypes)) {
        for (let [key, value] of Object.entries(values)) {
          if (this.$route.params.procedureKey.startsWith(key)) {
            return value
          }
        }
      }
      return 'teachers'
    },
  },
  beforeMount() {
    this.getProcedureTypes()
  },
  methods: {
    async getProcedureTypes() {
      this.procedureTypes = await UtilitiesService.getProcedureTypes()
    },
  },
}
</script>
