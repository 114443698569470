<template>
  <div>
    <b-modal :active.sync="isActive" :width="640" scroll="keep" has-modal-card trap-focus aria-role="dialog" aria-modal>
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body">
          <b-carousel
            v-model="slide"
            :arrow="arrow"
            :indicator="indicator"
            :indicator-inside="indicatorInside"
            :indicator-style="indicatorStyle"
            :autoplay="false"
          >
            <b-carousel-item>
              <div class="is-flex is-flex-align-center has-margin-bottom-medium">
                <faicon icon="cube" size="2x" class="has-margin-right" />
                <h1>{{ $t('beforeBegin') }}</h1>
              </div>

              <div>
                <div class="content">
                  <p class="is-size-3">
                    Por favor, tenha em atenção os seguintes pontos:
                  </p>
                  <ul>
                    <li>
                      O seu Curriculum Vitae deverá ser inserido em formato PDF, datado, assinado e organizado de acordo
                      com os critérios previstos no edital.
                    </li>
                    <li>
                      São admitidos apenas ficheiros em formato <strong>PDF</strong>, exceto na secção "Outros
                      trabalhos".
                    </li>
                    <li>
                      A sua candidatura ficará em <strong>Rascunho</strong> até ser <strong>Submetida</strong>. Se não
                      for submetida, <strong>não será considerada</strong>.
                    </li>
                    <li>
                      Até ao termo do prazo de candidaturas poderá efetuar alterações a uma candidatura já submetida,
                      clicando no botão “Reabrir”. Se reabrir a sua candidatura, a mesma voltará ao estado de
                      “Rascunho”, pelo que <strong>deverá voltar a submetê-la para que seja considerada</strong>.
                    </li>
                    <li>
                      Após submissão de candidatura irá receber email comprovativo da mesma.
                    </li>
                    <li>
                      Caso o botão "Submeter" não fique ativo, confirme se preencheu todas as secções obrigatórias.
                    </li>
                  </ul>
                </div>
                <div class="has-margin-top has-text-right">
                  <b-button type="is-text" size="is-large" @click="slide = 1">{{ $t('actions.next') }}</b-button>
                </div>
              </div>
            </b-carousel-item>
            <b-carousel-item>
              <div>
                <div class="is-flex is-flex-align-center has-margin-bottom-medium">
                  <faicon icon="question-circle" size="3x" class="has-margin-right" />
                  <h1>{{ $t('firstApplication.help') }}</h1>
                </div>
                <div class="has-margin-top-medium has-text-centered">
                  <div class="columns is-mobile">
                    <div class="column">
                      <p class="has-margin">
                        <faicon size="2x" icon="concierge-bell"></faicon>
                      </p>
                      <p>
                        {{ $t('firstApplication.support') }}
                      </p>
                    </div>
                    <div class="column">
                      <p class="has-margin">
                        <faicon size="2x" icon="envelope"></faicon>
                      </p>
                      <p>{{ $t('firstApplication.email') }} <a href="mailto:sgrh@uc.pt">sgrh@uc.pt</a>.</p>
                    </div>
                    <div class="column">
                      <p class="has-margin">
                        <faicon size="2x" icon="phone"></faicon>
                      </p>
                      <p>{{ $t('firstApplication.phone') }} 239 242720/31.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="has-margin-top-medium has-text-centered">
                <b-button type="is-text" size="is-large" @click="isActive = false">{{ $t('actions.close') }}</b-button>
              </div>
            </b-carousel-item>
          </b-carousel>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: this.show || false,
      slide: 0,
      arrow: false,
      indicator: false,
      indicatorInside: true,
      indicatorStyle: 'is-lines',
    }
  },
  watch: {
    show(value) {
      this.isActive = value
    },
  },
}
</script>
