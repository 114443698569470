<template>
  <MainLayout
    :title="$t('application.title') + ' ' + procedureCode"
    :short-title="$t('application.title') + ' ' + procedureCode"
    back-to="/"
    has-sidebar
  >
    <template v-slot:header-right-mobile>
      <div v-if="isEntryLoaded">
        <b-button
          v-if="!canSubmit && !isSubmitted && !isCancelled && canEdit"
          type="is-text"
          @click="doSaveApplication"
        >
          {{ $t('actions.save') }}
        </b-button>
        <b-button v-if="canOpen" type="is-text" @click="doReopen">{{ $t('actions.reopen') }}</b-button>
        <b-dropdown v-if="canSubmit && canEdit" position="is-bottom-left" aria-role="menu">
          <b-button slot="trigger" class="has-margin-left" role="button">
            <span>{{ $t('options') }}</span>
          </b-button>
          <b-dropdown-item v-if="canSubmit && canEdit" aria-role="menuitem" @click="doSaveApplication">
            {{ $t('actions.submit') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canEdit" aria-role="menuitem" @click="doCancelApplication">
            {{ $t('applicationState.cancel') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canOpen" aria-role="menuitem" @click="doReopen">
            {{ $t('applicationState.reopen') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <template v-slot:menu>
      <div>
        <div class="menu">
          <div class="menu-label">{{ $t('sections') }}</div>
          <scrollactive :offset="70">
            <ul class="menu-list">
              <li>
                <a href="#a" class="scrollactive-item">
                  <faicon v-if="isPersonalDataReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('personalData') }}
                </a>
              </li>
              <li>
                <a href="#b0" class="scrollactive-item">
                  <faicon v-if="isIncreaseCvPeriodReady && isDraft" class="active" icon="check-circle" />{{
                    $t('assessmentPeriod')
                  }}</a
                >
              </li>
              <li>
                <a href="#b" class="scrollactive-item">
                  <faicon v-if="isCvReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('cv.title') }}
                </a>
              </li>
              <li>
                <a href="#d" class="scrollactive-item"
                  ><faicon v-if="isCertificatesReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('certificates.title') }}
                </a>
              </li>
              <li>
                <a href="#e" class="scrollactive-item"
                  ><faicon v-if="isRelevantWorksReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('relevantWorks') }}
                </a>
              </li>
              <li>
                <a href="#f" class="scrollactive-item"
                  ><faicon v-if="isOtherCvWorksReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('otherWorks') }}
                </a>
              </li>
              <li class="has-margin-bottom-medium">
                <a href="#i" class="scrollactive-item"
                  ><faicon v-if="isAcceptenceReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('acceptance') }}</a
                >
              </li>
              <li class="menu-label">
                Opcionais
              </li>
              <li>
                <a href="#g" class="scrollactive-item">{{ $t('extraFiles') }}</a>
              </li>
              <li>
                <a href="#h" class="scrollactive-item">{{ $t('teleconference') }}</a>
              </li>
              <li>
                <a href="#j" class="scrollactive-item">{{ $t('handicap') }}</a>
              </li>
              <li>
                <a href="#i" class="scrollactive-item">{{ $t('additionalInformation') }}</a>
              </li>
            </ul>
          </scrollactive>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <div v-if="isEntryLoaded" class="panel is-hidden-tablet">
        <b-tag
          v-if="!isSubmitted"
          size="is-medium"
          :type="isEntryLoaded && entry.application.status == 'cancelled' ? 'is-black' : 'is-warning'"
          >{{ applicationStatus }}</b-tag
        >
        <b-tag v-else size="is-medium" type="is-primary">{{ $t('applicationState.submitted') }}</b-tag>
      </div>

      <b-message v-if="isDraft && canEdit" type="is-warning" size="is-small" class="has-margin-top">
        <div v-html="$t('mandatorySections')"></div>
      </b-message>

      <form id="a" class="panel" @submit.prevent="doSaveApplication" @keyup.enter="doSaveApplication">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('personalData') }}
              <faicon v-if="isPersonalDataReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field
            :label="$t('fullName')"
            :type="{ 'is-danger': $v.entry.application.name.$error }"
            :message="$v.entry.application.name.$error ? $t('required') : ''"
          >
            <b-input v-model="entry.application.name" :disabled="!canEdit" size="is-medium" type="text" name="name" />
          </b-field>

          <b-field
            :label="$t('address')"
            :type="{ 'is-danger': $v.entry.application.address_line1.$error }"
            :message="$v.entry.application.address_line1.$error ? $t('required') : ''"
          >
            <b-input v-model="entry.application.address_line1" :disabled="!canEdit" type="text" name="address_line1" />
          </b-field>
          <b-field :label="$t('address2')">
            <b-input
              v-model="entry.application.address_line2"
              :disabled="!canEdit"
              maxlength="200"
              type="text"
              name="address_line2"
            />
          </b-field>

          <b-field grouped>
            <b-field
              :label="$t('postalCode')"
              :type="{ 'is-danger': $v.entry.application.postal_code.$error }"
              :message="$v.entry.application.postal_code.$error ? $t('required') : ''"
            >
              <b-input
                v-model="entry.application.postal_code"
                size="is-width-small"
                :disabled="!canEdit"
                maxlength="8"
                type="text"
                placeholder="0000-000"
                name="postal_code"
              />
            </b-field>
            <b-field
              :label="$t('locality')"
              expanded
              :type="{ 'is-danger': $v.entry.application.locality.$error }"
              :message="$v.entry.application.locality.$error ? $t('required') : ''"
            >
              <b-input v-model="entry.application.locality" :disabled="!canEdit" type="text" name="locality" />
            </b-field>
          </b-field>

          <b-field
            :label="$t('country')"
            :type="{ 'is-danger': $v.entry.application.country.$error }"
            :message="$v.entry.application.phone_country.$error ? $t('required') : ''"
          >
            <CountriesSelect :disabled="!canEdit" :input="entry.application.country" @update="updateCountry" />
          </b-field>

          <b-field class="has-margin-top-large" grouped>
            <b-field
              :label="$t('phoneCountry')"
              :type="{ 'is-danger': $v.entry.application.phone_country.$error }"
              :message="$v.entry.application.phone_country.$error ? $t('required') : ''"
            >
              <PhoneCountriesSelect
                :disabled="!canEdit"
                :input="entry.application.phone_country"
                @update="updatePhoneCountry"
              />
            </b-field>

            <b-field
              :label="$t('phone')"
              expanded
              :type="{ 'is-danger': $v.entry.application.phone_number.$error }"
              :message="$v.entry.application.phone_number.$error ? $t('onlyNumber') : ''"
            >
              <b-input
                v-model="entry.application.phone_number"
                :disabled="!canEdit"
                type="text"
                maxlength="14"
                name="phone_number"
              />
            </b-field>
          </b-field>
        </div>
      </form>

      <div id="b0" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('assessmentPeriod') }}
              <faicon v-if="isIncreaseCvPeriodReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-content">
          <p v-if="canEdit">
            {{ $t('assessmentPeriodDescription') }}
          </p>
          <b-field :label="$t('assessmentPeriodYears')" class="has-margin-top-medium">
            <b-numberinput
              v-model="entry.application.increase_cv_period"
              :disabled="!canEdit"
              min="5"
              max="15"
              placeholder="Anos"
              @input="updateSwitch('increase_cv_period')"
            >
            </b-numberinput>
          </b-field>

          <div v-if="canEdit && entry.application.increase_cv_period > 5" class="files has-margin-top-medium">
            <h4>Comprovativo</h4>
            <p v-if="!isIncreaseCvPeriodReady" class="has-margin-top-small has-margin-bottom">
              Por favor, anexe o comprovativo que justifique o aumento do período de avaliação a considerar.
            </p>
            <p v-if="!getFilesByType('increase_cv_period_proof')" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('increase_cv_period_proof')"
              :key="file.id"
              :can-edit="canEdit"
              :allow-classified="false"
              :file="file"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit && !isIncreaseCvPeriodReady && entry.application.increase_cv_period > 5"
            reference-id="uploadIncreaseCvPeriodProof"
            allowed="pdf"
            :allow-classified="true"
            :clear-after="true"
            :limit="1"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/increase_cv_period_proof'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="b" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>{{ $t('cv.title') }}<faicon v-if="isCvReady" class="active" icon="check-circle" size="xs" /></h2>
          </div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <p v-if="canEdit && !isCvReady">
            Por favor, adicione no campo seguinte, um <strong>ficheiro PDF</strong> com o seu Curriculum Vitae,
            <strong>datado, assinado e organizado de acordo com os critérios previstos no edital</strong> e em
            <strong>conformidade</strong> com a <strong>declaração de alargamento</strong> do período considerado para
            avaliação do seu percurso, se aplicável.
          </p>

          <div class="files has-margin-top">
            <p v-if="!getFilesByType('cv').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('cv')"
              :key="file.id"
              :can-edit="canEdit"
              :allow-classified="false"
              :file="file"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit && !isCvReady"
            reference-id="uploadCv"
            allowed="pdf"
            :clear-after="true"
            :limit="1"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/cv'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="d" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('certificates.title') }}
              <faicon v-if="isCertificatesReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="entry.application" class="panel-body">
          <b-field :label="$t('certificates.phdDate')">
            <b-datepicker v-model="phdDate" :disabled="!isDraft || !canEdit" placeholder="Data" icon="calendar-plus">
            </b-datepicker>
          </b-field>
          <b-field :label="$t('certificates.phdCountry')">
            <b-select v-model="entry.application.phd_international" :disabled="!isDraft || !canEdit">
              <option :value="false">
                {{ $t('national') }}
              </option>
              <option :value="true">
                {{ $t('international') }}
              </option>
            </b-select>
          </b-field>

          <div
            v-if="isEntryLoaded && entry.application.phd_international"
            class="has-margin-top-medium box has-padding"
          >
            <p class="is-size-3">{{ $t('certificates.phdInternational') }}</p>
            <b-field v-if="entry.application.phd_international" :label="$t('certificates.phdInternationalDate')">
              <b-datepicker
                v-model="phdInternationalDate"
                :disabled="!isDraft || !canEdit"
                placeholder="Data"
                icon="calendar-plus"
                :date-formatter="dateFormatter"
              >
              </b-datepicker>
            </b-field>

            <b-field
              v-if="isEntryLoaded && entry.application.phd_international && isDraft"
              :label="$t('additionalInformation')"
            >
              <div class="html-editor">
                <editor-menu-bar
                  v-if="isDraft && canEdit"
                  v-slot="{ commands, isActive, focused }"
                  :editor="editorPhDRegistry"
                >
                  <div class="menubar" :class="{ 'is-focused': focused }">
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <icon name="bold" />
                    </button>
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <icon name="italic" />
                    </button>
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <icon name="underline" />
                    </button>
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph"
                    >
                      <icon name="paragraph" />
                    </button>
                    <button class="button is-text is-small" @click="commands.undo">
                      <icon name="undo" />
                    </button>
                    <button class="button is-text is-small" @click="commands.redo">
                      <icon name="redo" />
                    </button>
                  </div>
                </editor-menu-bar>
                <editor-content :editor="editorPhDRegistry" />
              </div>
            </b-field>
            <div v-else class="box has-margin-top">
              <div
                v-if="isEntryLoaded && entry.application.phd_international_notes"
                class="content"
                v-html="entry.application.phd_international_notes"
              ></div>
              <div v-else class="content" v-html="entry.application.phd_international_notes"></div>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <p class="has-text-weight-bold has-margin-top-medium has-margin-bottom">{{ $t('certificates.copies') }}</p>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-if="!loading && !entry.application.qualifications_certificate" class="panel-body">
          <p v-if="isDraft && canEdit">
            <span v-html="$t('certificates.files')"></span>
            <strong v-if="isEntryLoaded && entry.application.phd_international">
              {{ $t('certificates.phdInternationalProof') }}</strong
            >
          </p>

          <div class="files has-margin-top">
            <p v-if="!getFilesByType('certificate').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('certificate')"
              :key="file.id"
              :file="file"
              :allow-classified="false"
              :can-edit="canEdit"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit"
            reference-id="uploadCertificate"
            allowed="pdf"
            :clear-after="true"
            :limit="0"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/certificate'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="e" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('relevantWorks') }}
              <faicon v-if="isRelevantWorksReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <p
            v-if="isDraft && canEdit"
            v-html="
              $t('relevantWorksDescription', {
                min: entry.options.files.more_relevant.min || 3,
                max: entry.options.files.more_relevant.max || 6,
              })
            "
          ></p>

          <div class="files has-margin-top">
            <p v-if="!getFilesByType('more_relevant').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('more_relevant')"
              :key="file.id"
              allowed="pdf"
              :file="file"
              :can-edit="canEdit"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit && isRelevantWorksOpenToUpload"
            reference-id="uploadMoreRelevant"
            allowed="pdf"
            :clear-after="true"
            :limit="6"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/more_relevant'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="f" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('otherWorks') }} <faicon v-if="isOtherCvWorksReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <p v-if="isDraft && canEdit" v-html="$t('otherWorksDescription')"></p>
          <div class="files has-margin-top">
            <p v-if="!getFilesByType('other_cv').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('other_cv')"
              :key="file.id"
              :file="file"
              :can-edit="canEdit"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit"
            reference-id="uploadOtherCv"
            :clear-after="true"
            :limit="0"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/other_cv'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="i" class="panel">
        <div class="panel-header">
          <h2>
            {{ $t('acceptance') }} <faicon v-if="isAcceptenceReady" class="active" icon="check-circle" size="xs" />
          </h2>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_requirements"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_requirements')"
            >
              {{ $t('acceptPublicAdmin') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_native_language"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_native_language')"
            >
              {{ $t('acceptNativeSpeaker') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_documents_authenticity"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_documents_authenticity')"
            >
              {{ $t('acceptanceDocumentsAuthenticity') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.accept_notifications"
              :disabled="!canEdit"
              @input="doSaveApplication(true)"
            >
              {{ $t('acceptNotifications112') }}
            </b-switch>
          </b-field>
        </div>
      </div>

      <div id="g" class="panel">
        <div class="panel-header">
          <h2>{{ $t('extraFiles') }}</h2>
          <b-tag size="is-small">{{ $t('optional') }}</b-tag>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <p v-if="isDraft && canEdit" v-html="$t('userNotesFiles')"></p>

          <div class="files has-margin-top">
            <p v-if="!getFilesByType('other').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('other')"
              :key="file.id"
              :file="file"
              :can-edit="canEdit"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit"
            reference-id="uploadOther"
            :clear-after="true"
            :limit="0"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/other'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="h" class="panel">
        <div class="panel-header">
          <h2>{{ $t('teleconference') }}</h2>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field>
            <b-switch
              v-model="entry.application.remote_interview"
              :disabled="!canEdit"
              @input="updateSwitch('remote_interview')"
            >
              {{ $t('acceptTeleconference') }}
            </b-switch>
          </b-field>
        </div>
      </div>

      <div id="j" class="panel">
        <div class="panel-header">
          <h2>{{ $t('handicap') }}</h2>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field>
            <b-switch v-model="entry.application.is_handycap" :disabled="!canEdit" @input="updateSwitch('is_handycap')"
              >{{ $t('isHandicap') }}
            </b-switch>
          </b-field>
        </div>
      </div>

      <div id="i" class="panel">
        <div class="panel-header">
          <h2>{{ $t('additionalInformation') }}</h2>
          <b-tag size="is-small">{{ $t('optional') }}</b-tag>
        </div>
        <div class="panel-content">
          <b-field v-if="isDraft" :label="$t('otherInformation')" :message="$t('atLeast50Chars')">
            <div class="html-editor">
              <editor-menu-bar v-if="isDraft && canEdit" v-slot="{ commands, isActive, focused }" :editor="editor">
                <div class="menubar" :class="{ 'is-focused': focused }">
                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.bold() }"
                    @click="commands.bold"
                  >
                    <icon name="bold" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.italic() }"
                    @click="commands.italic"
                  >
                    <icon name="italic" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.underline() }"
                    @click="commands.underline"
                  >
                    <icon name="underline" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.paragraph() }"
                    @click="commands.paragraph"
                  >
                    <icon name="paragraph" />
                  </button>

                  <button class="button is-text is-small" @click="commands.undo">
                    <icon name="undo" />
                  </button>

                  <button class="button is-text is-small" @click="commands.redo">
                    <icon name="redo" />
                  </button>
                </div>
              </editor-menu-bar>
              <editor-content :editor="editor" />
            </div>
          </b-field>
          <div v-if="!isDraft && isEntryLoaded" class="has-margin-top">
            <div
              v-if="entry.application.user_notes"
              class="content box has-padding"
              v-html="entry.application.user_notes"
            ></div>
            <div v-else class="instructions">{{ $t('noContent') }}</div>
          </div>
        </div>
      </div>

      <div v-if="canSubmit || isDraft || canEdit">
        <hr />
        <b-field grouped>
          <b-field v-if="canSubmit && isDraft && canEdit" class="has-margin-bottom">
            <b-button type="is-primary" outlined @click="doSubmitApplication">{{ $t('actions.submit') }}</b-button>
          </b-field>
          <b-field expanded>
            <b-button
              v-if="!isSubmitted && !isCancelled && canEdit"
              type="is-text"
              :loading="loadingSave"
              @click="doSaveApplication"
              >{{ $t('actions.save') }}</b-button
            >
          </b-field>
          <b-field>
            <b-button v-if="canOpen" type="is-text" @click="doReopen">{{ $t('actions.reopen') }}</b-button>
          </b-field>
          <b-field>
            <b-button v-if="!canOpen && canEdit" class="has-text-dark" type="is-text" @click="doCancelApplication">{{
              $t('actions.cancel')
            }}</b-button>
          </b-field>
        </b-field>
      </div>
    </template>

    <template v-slot:sidebar>
      <ContentLoader v-if="loading" type="sidebar" />
      <div v-else>
        <div class="has-margin-bottom">
          <div class="label is-marginless">{{ $t('status') }}</div>
          <div
            class="is-size-1 has-text-weight-bold"
            :class="{
              'has-text-primary': entry.application.status == 'submitted',
            }"
          >
            {{ getItemStatusDescription(entry.application.status) }}
          </div>
        </div>

        <div v-if="canEdit || canCancel || canOpen" class="label">{{ $t('operations') }}</div>

        <b-field v-if="isDraft" class="has-margin-bottom">
          <b-button
            expanded
            size="is-medium"
            :disabled="!canSubmit && canEdit"
            :type="{
              'is-outlined': !canSubmit && canEdit,
              'is-primary': canSubmit && canEdit,
            }"
            @click="doSubmitApplication"
            >{{ $t('actions.submit') }}</b-button
          >
        </b-field>

        <b-field v-if="canEdit || canCancel || canOpen" grouped class="has-margin-bottom has-margin-top">
          <b-field expanded>
            <b-button v-if="canOpen" type="is-black" expanded :loading="loadingSave" @click="doReopen">{{
              $t('actions.reopen')
            }}</b-button>
            <b-button
              v-if="!isSubmitted && !isCancelled && canEdit"
              :type="{
                'is-outlined': canSubmit,
                'is-primary': !canSubmit,
              }"
              expanded
              :loading="loadingSave"
              @click="doSaveApplication"
              >{{ $t('actions.save') }}</b-button
            >
          </b-field>
          <b-field>
            <b-button outlined @click="doCancelApplication">{{ $t('actions.cancel') }}</b-button>
          </b-field>
        </b-field>

        <hr />

        <div>
          <h4 class="has-margin-bottom">{{ $t('support.needHelp') }}?</h4>
          <button class="button is-small" @click="goToSupport">
            <faicon icon="concierge-bell"></faicon> {{ $t('support.ticket.new') }}
          </button>
        </div>

        <div class="has-margin-top-large">
          <p class="is-meta has-margin-bottom">{{ $t('procedure.details') }}</p>
          <p>
            <router-link :to="'/procedure/' + entry.procedure.code">
              <faicon icon="hashtag" class="has-text-small"></faicon>
              {{ entry.procedure.code }}
            </router-link>
          </p>
          <p class="has-margin-top has-margin-bottom">{{ procedureTitle }}</p>
          <p class="is-meta">
            <span v-if="entry.procedure.area">Área disciplinar de {{ entry.procedure.area }}</span>
            <span v-if="entry.procedure.sub_area">, subárea de {{ entry.procedure.sub_area }}</span
            >.
          </p>
          <p class="is-meta has-margin-top">
            <span v-if="entry.procedure.department_title">{{ entry.procedure.department_title }} / </span>
            <span v-if="entry.procedure.faculty_title">{{ entry.procedure.faculty_title }}</span>
          </p>
        </div>
      </div>
    </template>

    <template v-slot:modals>
      <ModalFirstApplication :show.sync="isFirstApplicationModalActive" />
      <ModalAfterSubmit :show.sync="isAfterSubmitModalActive" />
      <ModalReadyToSubmit :show.sync="isReadyToSubmitModalActive" />
      <ModalDownloading :show.sync="isDownloadingFile" :percentage.sync="$store.state.isDownloading" />
    </template>
  </MainLayout>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import { required } from 'vuelidate/lib/validators'
import { minLength } from 'vuelidate/lib/validators'
import { numeric } from 'vuelidate/lib/validators'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, Italic, Link, Underline, History, Placeholder } from 'tiptap-extensions'

import ApplicationService from '@/services/ApplicationService'
import ProcedureService from '@/services/ProcedureService'
import UtilitiesService from '@/services/UtilitiesService'

import MainLayout from '@/components/layouts/MainLayout'

import Uploader from '@/components/utilities/Uploader'
import ContentLoader from '@/components/utilities/ContentLoader'
import RecordFileEntry from '@/components/records/RecordFileEntry'
import ModalAfterSubmit from '@/components/modals/ModalAfterSubmit'
import ModalReadyToSubmit from '@/components/modals/ModalReadyToSubmit'
import ModalDownloading from '@/components/modals/ModalDownloading'
import ModalFirstApplication from '@/components/modals/ModalFirstApplication'
import CountriesSelect from '@/components/utilities/CountriesSelect'
import PhoneCountriesSelect from '@/components/utilities/PhoneCountriesSelect'

export default {
  components: {
    MainLayout,
    Uploader,
    ContentLoader,
    RecordFileEntry,
    EditorContent,
    EditorMenuBar,
    CountriesSelect,
    PhoneCountriesSelect,
    ModalAfterSubmit,
    ModalReadyToSubmit,
    ModalFirstApplication,
    ModalDownloading,
  },

  data() {
    return {
      loading: false,
      loadingSave: false,

      user: this.$store.getters.getUser,

      entry: {},

      // Editor para notas adicionais (opcional)
      editor: new Editor({
        editable: this.isDraft && this.canEdit,
        extensions: [
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.$t('writeText'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.entry.application.user_notes = getHTML()
        },
      }),

      // Editor para notas sobre o doutoramento (caso internacional)
      editorPhDRegistry: new Editor({
        editable: this.isDraft && this.canEdit,
        extensions: [
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.$t('writeText'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.entry.application.phd_international_notes = getHTML()
        },
      }),

      isFirstApplicationModalActive: false,
      isAfterSubmitModalActive: false,
      isReadyToSubmitModalActive: false,

      phdDate: new Date(),
      phdInternationalDate: new Date(),
    }
  },

  computed: {
    isDownloadingFile() {
      return this.$store.state.isDownloading > 0 && this.$store.state.isDownloading < 100
    },
    // Testes do estado da candidatura
    isEntryLoaded() {
      return this.entry && this.entry.application
    },

    procedureCode() {
      return this.$route.params.procedureKey
    },

    applicationStatus() {
      return ApplicationService.helpers.getStatusMap(this.entry.application.status)
    },

    procedureTitle() {
      return ProcedureService.helpers.getProcedureTitle(this.entry.procedure)
    },

    isDraft() {
      return this.entry && this.entry.application && this.entry.application.status == 'draft'
    },

    isSubmitted() {
      return this.entry && this.entry.application && this.entry.application.status == 'submitted'
    },

    isCancelled() {
      return this.entry && this.entry.application && this.entry.application.status == 'cancelled'
    },

    canEdit() {
      return this.entry && this.entry.permissions && this.entry.permissions.edit
    },

    canSubmit() {
      return (
        this.isEntryLoaded &&
        this.entry.permissions &&
        this.entry.permissions.submit &&
        this.isPersonalDataReady &&
        this.isCvReady &&
        this.isIncreaseCvPeriodReady &&
        this.isCertificatesReady &&
        this.isRelevantWorksReady &&
        this.isAcceptenceReady
      )
    },

    canCancel() {
      return this.entry.permissions.change_status
    },

    canOpen() {
      return (
        this.entry &&
        this.entry.application &&
        this.entry.application.status != 'draft' &&
        this.entry.permissions.change_status
      )
    },

    // Testes de validação
    isPersonalDataReady() {
      if (!isEmpty(this.entry)) {
        return Boolean(
          this.entry.application.address_line1 &&
            !isEmpty(this.entry.application.country) &&
            this.entry.application.locality &&
            this.entry.application.postal_code &&
            this.entry.application.name &&
            !isEmpty(this.entry.application.phone_country) &&
            this.entry.application.phone_number
        )
      }
      return false
    },

    isIncreaseCvPeriodReady() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('increase_cv_period_proof')
        return (
          this.entry.application.increase_cv_period == 5 ||
          (this.entry.application.increase_cv_period > 5 && files && files.length == 1)
        )
      }
      return false
    },

    isCvReady() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('cv')
        return files && files.length == 1
      }
      return false
    },

    isCertificatesReady() {
      if (!isEmpty(this.entry)) {
        if (!this.entry.application.phd_date) {
          return false
        } else if (this.entry.application.phd_international && !this.entry.application.phd_international_date) {
          return false
        }

        // Valida ficheiros
        const files = this.getFilesByType('certificate')

        // No caso de doutoramento internacional,
        // o comprovativo pode ser entregue até à fase de candidatura
        if (this.entry.application.phd_international) {
          return true
        }

        if (!this.entry.application.qualifications_certificate) {
          return Boolean(files && files.length)
        } else {
          return true
        }
      }
      return false
    },

    isRelevantWorksReady() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('more_relevant')
        return files && files.length >= 3
      }
      return false
    },

    isOtherCvWorksReady() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('other_cv')
        return files && files.length
      }
      return false
    },

    isRelevantWorksOpenToUpload() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('more_relevant')
        return files && files.length < 6
      }
      return false
    },

    isAcceptenceReady() {
      if (!isEmpty(this.entry)) {
        return Boolean(
          this.entry.application.acceptance_requirements &&
            this.entry.application.acceptance_documents_authenticity &&
            this.entry.application.accept_notifications
        )
      }
      return false
    },
  },

  created() {
    this.showFirstApplicationModal()
  },

  beforeDestroy() {
    this.editor.destroy()
    this.editorPhDRegistry.destroy()
  },

  beforeMount() {
    this.getApplication()
  },

  // Valida o formulário de dados pessoais
  validations: {
    entry: {
      application: {
        name: {
          required,
          min: minLength(2),
        },
        address_line1: {
          required,
          min: minLength(2),
        },
        postal_code: {
          required,
        },
        locality: {
          required,
        },
        country: {
          required,
        },
        phone_country: {
          required,
        },
        phone_number: {
          required,
          numeric,
        },
      },
    },
  },

  methods: {
    getItemStatusDescription(status) {
      const map = {
        draft: this.$t('draft'),
        cancelled: this.$t('cancelled'),
        submitted: this.$t('submitted'),
      }
      return map[status]
    },

    dateFormatter(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },

    updateCountry(value) {
      this.entry.application.country = value
    },

    updatePhoneCountry(value) {
      this.entry.application.phone_country = value
    },

    // Obtém o ojecto da candidatura
    async getApplication() {
      this.loading = true

      if (this.$route.params.action == 'start') {
        this.entry = await ApplicationService.add(this.$route.params.procedureKey)
        this.$router.replace({ ...this.$router.currentRoute, params: { action: 'view' } })
      }

      this.entry = await ApplicationService.get(this.$route.params.procedureKey)

      // Popula o nome com os dados do perfil e define o país default como Portugal
      if (!this.entry.application.name) {
        this.entry.application.name = this.user.full_name || ''
      }

      if (!this.entry.application.increase_cv_period) {
        this.entry.application.increase_cv_period = 5
      }

      // Converte das datas
      if (this.entry.application.phd_date) {
        this.phdDate = dayjs(this.entry.application.phd_date).toDate()
      }
      if (this.entry.application.phd_international_date) {
        this.phdInternationalDate = dayjs(this.entry.application.phd_international_date).toDate()
      }

      // Popula os editores de texto com o conteúdos definido pelo utilizador
      this.editor.setContent(this.entry.application.user_notes)
      this.editorPhDRegistry.setContent(this.entry.application.phd_international_notes)

      this.loading = false
    },

    // Envia a candidatura para o backend e utiliza o novo objeto no dom
    doSaveApplication(silenceNotification = false) {
      this.loadingSave = true

      this.$v.$touch()

      // Até encontrar forma melhor...
      this.entry.application.phd_date = dayjs(this.phdDate).format('YYYY-MM-DD')
      this.entry.application.phd_international_date = dayjs(this.phdInternationalDate).format('YYYY-MM-DD')

      ApplicationService.update(this.entry.application)
        .then(response => {
          this.entry = response.data
          this.loadingSave = false

          // Apresenta modal caso esteja pronto para submeter (primeira vez)
          // Valida se o utilizador já viu a modal de primeira submissão neste procedimento especifico
          if (this.canSubmit) {
            const optionKey = 'modal-ready-to-submit-' + this.entry.application.code
            UtilitiesService.getOption(optionKey).then(response => {
              if (!response.data) {
                UtilitiesService.setOption(optionKey, true).then(() => {
                  setTimeout(() => {
                    this.isReadyToSubmitModalActive = true
                  }, 500)
                })
              }
            })
          }

          if (!silenceNotification) {
            this.$buefy.snackbar.open({
              message: this.$t('applicationState.saved'),
              type: 'is-primary',
              position: 'is-bottom-right',
              duration: 2000,
              queue: false,
            })
          }
        })
        .catch(err => {
          this.alertErrorCallEnded(err.response.status)
        })
    },

    doCancelApplication() {
      this.$buefy.dialog.confirm({
        title: this.$t('applicationState.cancel'),
        message: this.$t('applicationState.cancelConfirm'),
        confirmText: this.$t('applicationState.cancelYes'),
        cancelText: this.$t('applicationState.cancelNo'),
        onConfirm: async () => {
          this.entry.application.status = 'cancelled'

          ApplicationService.update(this.entry.application)
            .then(response => {
              this.entry = response.data
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    doReopen() {
      this.$buefy.dialog.confirm({
        title: this.$t('applicationState.reopen'),
        message: this.$t('applicationState.reopenConfirm'),
        confirmText: this.$t('actions.reopen'),
        type: 'is-black',
        onConfirm: async () => {
          this.entry.application.status = 'draft'

          ApplicationService.update(this.entry.application)
            .then(response => {
              this.entry = response.data
              this.$buefy.notification.open({
                type: 'is-black',
                message: this.$t('applicationState.reopenDone'),
                duration: 2000,
              })
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    doSubmitApplication() {
      this.$buefy.dialog.confirm({
        size: 'is-large',
        cancelText: this.$t('actions.cancel'),
        title: this.$t('applicationState.submit'),
        message: this.$t('applicationState.submitConfirm'),
        onConfirm: async () => {
          // Cria novo objeto para garantir que é apenas considerado se válido no backend
          const updatedApplication = cloneDeep(this.entry.application)
          updatedApplication.status = 'submitted'

          ApplicationService.update(updatedApplication)
            .then(response => {
              this.entry = response.data
              this.showAfterSubmitModal()
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    // Atualiza todas as opções para simplificar
    async updateSwitch() {
      this.doSaveApplication(true)
    },

    // Gestão de ficheiros
    async downloadFile(file) {
      await ApplicationService.downloadFile(file.key, file.filename)
    },

    removeFile(file) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          const fileDeleted = await ApplicationService.deleteFile(file.key)
          if (fileDeleted) {
            this.doSaveApplication(true)
          }
        },
      })
    },

    updateFilesCart() {
      this.doSaveApplication(true)
    },

    getFilesByType(type) {
      if (!this.entry.application.files) {
        return
      }
      return this.entry.application.files.filter(file => {
        return file.type == type
      })
    },

    goToSupport() {
      if (this.isDraft) {
        this.$buefy.dialog.confirm({
          title: this.$t('saveBeforeLeave'),
          message: this.$t('saveBeforeTicket'),
          cancelText: this.$t('actions.cancel'),
          confirmText: this.$t('saveLeave'),
          type: 'is-primary',
          onConfirm: () => {
            this.doSaveApplication(true)
            this.$router.push({ path: '/support', query: { procedureCode: this.entry.procedure.code, openNew: true } })
          },
        })
      } else {
        this.$router.push({ path: '/support', query: { procedureCode: this.entry.procedure.code, openNew: true } })
      }
    },

    // Modais
    showAfterSubmitModal() {
      this.isAfterSubmitModalActive = true
    },

    showFirstApplicationModal() {
      const optionIntro = 'first-application-v1'
      UtilitiesService.getOption(optionIntro).then(response => {
        if (!response.data) {
          UtilitiesService.setOption(optionIntro, true)
          setTimeout(() => {
            this.isFirstApplicationModalActive = true
          }, 2000)
        }
      })
    },

    alertErrorCallEnded(status) {
      // Em caso de erro, obter novamente a candidatura do lado da api
      this.getApplication()

      if (status == 403) {
        this.$buefy.dialog.alert({
          title: this.$t('applicationState.closed'),
          message: this.$t('applicationState.closedDescription'),
          type: 'is-black',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }

      // Ocorreu algo inesperado
      else {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: this.$t('applicationState.internalError'),
          duration: 5000,
        })
      }
    },
  },
}
</script>
