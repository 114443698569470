<template>
  <div>
    <b-modal :active.sync="isActive" :width="500" class="modal-hero background-primary">
      <div class="panel">
        <div class="panel-body has-text-centered">
          <h1 class="is-size-2"><faicon icon="check-circle" /> {{ $t('applicationState.submitted') }}</h1>
          <p class="has-margin">
            Até ao fim do prazo de abertura, pode reabrir ou mesmo cancelar a sua candidatura. Em caso de reabertura,
            deverá voltar a submeter a mesma para que o processo seja considerado.
          </p>
          <div class="has-margin-top">
            <b-button type="is-black" outlined @click="isActive = false">{{ $t('actions.next') }}</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: this.show || false,
      slide: 0,
      arrow: false,
      indicator: true,
      indicatorInside: true,
      indicatorStyle: 'is-lines',
    }
  },
  watch: {
    show(value) {
      this.isActive = value
    },
  },
}
</script>
