<template>
  <div>
    <multiselect
      v-model="value"
      :disabled="disabled"
      :options="countries"
      :show-labels="false"
      :clear-on-select="false"
      :allow-empty="false"
      placeholder="Escolha um país"
      label="title"
      track-by="title"
      :max-height="200"
      @input="updateParent"
    ></multiselect>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Multiselect from 'vue-multiselect'
import UtilitiesService from '@/services/UtilitiesService'

export default {
  components: {
    Multiselect,
  },
  props: {
    input: Object,
    disabled: Boolean,
  },
  data() {
    return {
      countries: [],
      value: null,
    }
  },
  mounted() {
    this.getCountries()

    // Popular a opção selecionada
    this.value = this.input
  },
  methods: {
    updateParent() {
      this.$emit('update', this.value)
    },
    async getCountries() {
      this.countries = await UtilitiesService.getCountries()

      // Define Portugal como default para facilitar o preenchimento
      if (isEmpty(this.input)) {
        this.value = this.countries[this.countries.findIndex(item => item.key == 'PT')]
        this.updateParent()
      }
    },
  },
}
</script>
