<template>
  <MainLayout
    :title="$t('application.title') + ' ' + procedureCode"
    :short-title="$t('application.title') + ' ' + procedureCode"
    back-to="/"
    has-sidebar
  >
    <template v-slot:header-right-mobile>
      <div v-if="isEntryLoaded">
        <b-button
          v-if="!canSubmit && !isSubmitted && !isCancelled && canEdit"
          type="is-text"
          @click="doSaveApplication"
        >
          {{ $t('actions.save') }}
        </b-button>
        <b-button v-if="canOpen" type="is-text" @click="doReopen">{{ $t('actions.reopen') }}</b-button>
        <b-dropdown v-if="canSubmit && canEdit" position="is-bottom-left" aria-role="menu">
          <b-button slot="trigger" class="has-margin-left" role="button">
            <span>{{ $t('options') }}</span>
          </b-button>
          <b-dropdown-item v-if="canSubmit && canEdit" aria-role="menuitem" @click="doSaveApplication">
            {{ $t('actions.submit') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canEdit" aria-role="menuitem" @click="doCancelApplication">
            {{ $t('applicationState.cancel') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canOpen" aria-role="menuitem" @click="doReopen">
            {{ $t('applicationState.reopen') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <template v-slot:menu>
      <div>
        <div class="menu">
          <div class="menu-label">{{ $t('sections') }}</div>
          <scrollactive :offset="70">
            <ul class="menu-list">
              <li>
                <a href="#a" class="scrollactive-item">
                  <faicon v-if="isPersonalDataReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('personalData') }}
                </a>
              </li>
              <li>
                <a href="#b" class="scrollactive-item">
                  <faicon v-if="isCvReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('cv.title') }}
                </a>
              </li>
              <li>
                <a href="#c" class="scrollactive-item"
                  ><faicon v-if="isCareerPlanReady && isDraft" class="active" icon="check-circle" /> {{ $t('career') }}
                </a>
              </li>
              <li>
                <a href="#d" class="scrollactive-item"
                  ><faicon v-if="isCertificatesReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('certificates.title') }}
                </a>
              </li>
              <li>
                <a href="#e" class="scrollactive-item"
                  ><faicon v-if="isRelevantWorksReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('relevantWorks') }}
                </a>
              </li>
              <li>
                <a href="#f" class="scrollactive-item"
                  ><faicon v-if="isOtherCvWorksReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('otherWorks') }}
                </a>
              </li>
              <li>
                <a href="#m" class="scrollactive-item"
                  ><faicon v-if="isMeritJustificationReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('meritJustification.title') }}
                </a>
              </li>
              <li class="has-margin-bottom-medium">
                <a href="#i" class="scrollactive-item"
                  ><faicon v-if="isAcceptenceReady && isDraft" class="active" icon="check-circle" />
                  {{ $t('acceptance') }}</a
                >
              </li>
              <li class="menu-label">
                Opcionais
              </li>
              <li>
                <a href="#g" class="scrollactive-item">{{ $t('extraFiles') }}</a>
              </li>
              <li>
                <a href="#h" class="scrollactive-item">{{ $t('teleconference') }}</a>
              </li>
              <li>
                <a href="#i" class="scrollactive-item">{{ $t('additionalInformation') }}</a>
              </li>
            </ul>
          </scrollactive>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <div v-if="isEntryLoaded" class="panel is-hidden-tablet">
        <b-tag
          v-if="!isSubmitted"
          size="is-medium"
          :type="isEntryLoaded && entry.application.status == 'cancelled' ? 'is-black' : 'is-warning'"
          >{{ applicationStatus }}</b-tag
        >
        <b-tag v-else size="is-medium" type="is-primary">{{ $t('applicationState.submitted') }}</b-tag>
      </div>

      <b-message v-if="isDraft && canEdit" type="is-warning" size="is-small" class="has-margin-top">
        <div v-html="$t('mandatorySections')"></div>
      </b-message>

      <form id="a" class="panel" @submit.prevent="doSaveApplication" @keyup.enter="doSaveApplication">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('personalData') }}
              <faicon v-if="isPersonalDataReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field
            :label="$t('fullName')"
            :type="{ 'is-danger': $v.entry.application.name.$error }"
            :message="$v.entry.application.name.$error ? $t('required') : ''"
          >
            <b-input v-model="entry.application.name" :disabled="!canEdit" size="is-medium" type="text" name="name" />
          </b-field>

          <b-field
            :label="$t('address')"
            :type="{ 'is-danger': $v.entry.application.address_line1.$error }"
            :message="$v.entry.application.address_line1.$error ? $t('required') : ''"
          >
            <b-input v-model="entry.application.address_line1" :disabled="!canEdit" type="text" name="address_line1" />
          </b-field>
          <b-field :label="$t('address2')">
            <b-input
              v-model="entry.application.address_line2"
              :disabled="!canEdit"
              maxlength="200"
              type="text"
              name="address_line2"
            />
          </b-field>

          <b-field grouped>
            <b-field
              :label="$t('postalCode')"
              :type="{ 'is-danger': $v.entry.application.postal_code.$error }"
              :message="$v.entry.application.postal_code.$error ? $t('required') : ''"
            >
              <b-input
                v-model="entry.application.postal_code"
                size="is-width-small"
                :disabled="!canEdit"
                maxlength="8"
                type="text"
                placeholder="0000-000"
                name="postal_code"
              />
            </b-field>
            <b-field
              :label="$t('locality')"
              expanded
              :type="{ 'is-danger': $v.entry.application.locality.$error }"
              :message="$v.entry.application.locality.$error ? $t('required') : ''"
            >
              <b-input v-model="entry.application.locality" :disabled="!canEdit" type="text" name="locality" />
            </b-field>
          </b-field>

          <b-field
            :label="$t('country')"
            :type="{ 'is-danger': $v.entry.application.country.$error }"
            :message="$v.entry.application.phone_country.$error ? $t('required') : ''"
          >
            <CountriesSelect :disabled="!canEdit" :input="entry.application.country" @update="updateCountry" />
          </b-field>

          <b-field class="has-margin-top-large" grouped>
            <b-field
              :label="$t('phoneCountry')"
              :type="{ 'is-danger': $v.entry.application.phone_country.$error }"
              :message="$v.entry.application.phone_country.$error ? $t('required') : ''"
            >
              <PhoneCountriesSelect
                :disabled="!canEdit"
                :input="entry.application.phone_country"
                @update="updatePhoneCountry"
              />
            </b-field>

            <b-field
              :label="$t('phone')"
              expanded
              :type="{ 'is-danger': $v.entry.application.phone_number.$error }"
              :message="$v.entry.application.phone_number.$error ? $t('onlyNumber') : ''"
            >
              <b-input
                v-model="entry.application.phone_number"
                :disabled="!canEdit"
                type="text"
                maxlength="14"
                name="phone_number"
              />
            </b-field>
          </b-field>

          <div>
            <p v-html="$t('personalDataFile')"></p>
            <div class="files has-margin-top">
              <p v-if="!getFilesByType('personal_data').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </p>
              <RecordFileEntry
                v-for="file in getFilesByType('personal_data')"
                :key="file.id"
                :can-edit="canEdit"
                :file="file"
                :allow-classified="false"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>

            <Uploader
              v-if="canEdit && !getFilesByType('personal_data').length"
              reference-id="uploadPersonalData"
              allowed="pdf"
              :clear-after="true"
              :limit="1"
              :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/personal_data'"
              @upload="updateFilesCart"
            />
          </div>
        </div>
      </form>

      <div id="b" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>Curriculum Vitae <faicon v-if="isCvReady" class="active" icon="check-circle" size="xs" /></h2>
            <p></p>
          </div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <div v-if="entry.application" class="panel-body">
            <div>
              <p v-html="$t('cv.filesNotice')"></p>
              <div class="files has-margin-top">
                <p v-if="!getFilesByType('cv').length" class="instructions">
                  {{ $t('noSectionDocuments') }}
                </p>
                <RecordFileEntry
                  v-for="file in getFilesByType('cv')"
                  :key="file.id"
                  :can-edit="canEdit"
                  :file="file"
                  :allow-classified="false"
                  @remove="removeFile"
                  @download="downloadFile"
                />
              </div>

              <Uploader
                v-if="canEdit && !isCvReady"
                reference-id="uploadCv"
                allowed="pdf"
                :clear-after="true"
                :limit="1"
                :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/cv'"
                @upload="updateFilesCart"
              />
            </div>

            <h3 class="has-margin-top-medium has-margin-bottom-small">{{ $t('cv.contractualRelationships.title') }}</h3>

            <p class="help is-meta">
              {{ $t('cv.contractualRelationships.message') }}
            </p>

            <div v-if="!withContractualRelationships" class="instructions">
              {{ $t('entry.empty') }}
            </div>
            <div v-else class="has-margin-bottom">
              <div
                v-for="record in entry.application.contractual_relationships"
                :key="record.$index"
                class="record has-background-light has-padding-small"
              >
                <div class="record-content is-paddingless">
                  <div class="has-text-small label" style="margin-bottom: 0 !important;">
                    {{ $t('cv.contractualRelationships.category') }}
                  </div>
                  <div class="record-title has-margin-bottom-small">{{ record.category }}</div>
                  <div class="has-text-small label" style="margin-bottom: 0 !important;">
                    {{ $t('cv.contractualRelationships.natureOfTheBond') }}
                  </div>
                  <div class="has-text-small has-margin-bottom-small">{{ record.nature_of_the_bond }}</div>
                  <div class="has-text-small label" style="margin-bottom: 0 !important;">
                    {{ $t('cv.contractualRelationships.areas') }}
                  </div>
                  <div class="has-text-small has-margin-bottom-small">{{ record.areas }}</div>
                  <div class="has-text-small label" style="margin-bottom: 0 !important;">
                    {{ $t('cv.contractualRelationships.educationInstitution') }}
                  </div>
                  <div class="has-text-small has-margin-bottom-small has-margin-bottom-small">
                    {{ record.education_institution }}
                  </div>
                  <div class="has-text-small label" style="margin-bottom: 0 !important;">
                    {{ $t('cv.contractualRelationships.period') }}
                  </div>
                  <div v-if="record.end_date" class="has-text-small is-flex">
                    <div>De {{ record.start_date | formatDate }} a {{ record.end_date | formatDate }}</div>
                  </div>
                  <div v-else class="has-text-small">
                    <div>De {{ record.start_date | formatDate }}</div>
                  </div>
                </div>
                <div v-if="canEdit" class="record-nav">
                  <b-button
                    size="is-small"
                    type="is-danger"
                    outlined
                    @click="removeListEntry(record.key, 'contractual_relationships')"
                  >
                    {{ $t('actions.delete') }}
                  </b-button>
                </div>
              </div>
            </div>
            <div
              v-if="addContractualRelationshipsActive && canEdit"
              class="has-background-light has-padding-medium is-rounded has-margin-bottom"
            >
              <h3 class="has-text-primary has-margin-bottom">{{ $t('entry.new') }}</h3>

              <b-field
                :label="$t('cv.contractualRelationships.category')"
                expanded
                :type="{ 'is-danger': newContractualRelationshipsEntry.$errors.category }"
                :message="newContractualRelationshipsEntry.$errors.category"
              >
                <b-input v-model="newContractualRelationshipsEntry.category" name="category" />
              </b-field>

              <b-field
                :label="$t('cv.contractualRelationships.natureOfTheBond')"
                :type="{ 'is-danger': newContractualRelationshipsEntry.$errors.nature_of_the_bond }"
                :message="newContractualRelationshipsEntry.$errors.nature_of_the_bond"
              >
                <b-input v-model="newContractualRelationshipsEntry.nature_of_the_bond" name="nature_of_the_bond" />
              </b-field>

              <b-field
                :label="$t('cv.contractualRelationships.areas')"
                :type="{ 'is-danger': newContractualRelationshipsEntry.$errors.areas }"
                :message="newContractualRelationshipsEntry.$errors.areas"
              >
                <b-input v-model="newContractualRelationshipsEntry.areas" name="areas" />
              </b-field>

              <b-field
                :label="$t('cv.contractualRelationships.educationInstitution')"
                :type="{ 'is-danger': newContractualRelationshipsEntry.$errors.education_institution }"
                :message="newContractualRelationshipsEntry.$errors.education_institution"
              >
                <b-input
                  v-model="newContractualRelationshipsEntry.education_institution"
                  name="education_institution"
                />
              </b-field>

              <b-field
                :label="$t('dates.start')"
                :type="{ 'is-danger': newContractualRelationshipsEntry.$errors.start_date }"
                :message="newContractualRelationshipsEntry.$errors.start_date"
              >
                <b-datepicker
                  v-model="newContractualRelationshipsEntry.start_date"
                  :placeholder="$t('dates.start')"
                  class="is-width-medium"
                  icon="calendar-plus"
                >
                </b-datepicker>
              </b-field>

              <b-field
                :label="$t('dates.end') + '(' + $t('optional') + ')'"
                :type="{ 'is-danger': newContractualRelationshipsEntry.$errors.end_date }"
                :message="newContractualRelationshipsEntry.$errors.end_date"
              >
                <b-datepicker
                  v-model="newContractualRelationshipsEntry.end_date"
                  :placeholder="$t('dates.done')"
                  class="is-width-medium"
                  icon="calendar-plus"
                  :min-date="newContractualRelationshipsEntry.start_date"
                >
                </b-datepicker>
              </b-field>

              <div class="is-flex is-flex-justify-right has-margin-top">
                <b-button type="is-light" class="has-margin-right" @click="addContractualRelationshipsActive = false">
                  {{ $t('actions.cancel') }}
                </b-button>
                <b-button type="is-primary" :loading="loadingNewItem" outlined @click="addContractualRelationships">
                  {{ $t('actions.add') }}
                </b-button>
              </div>
            </div>
            <div v-if="!addContractualRelationshipsActive && canEdit" class="has-margin-top has-margin-bottom-medium">
              <b-button type="is-text" outlined icon-left="plus" @click="addContractualRelationshipsActive = true">
                {{ $t('entry.add') }}
              </b-button>
            </div>
          </div>

          <div class="has-margin-top">
            <h3 class="has-margin-bottom-small">{{ $t('cv.requiredSpecialty.label') }}</h3>
            <b-field
              :type="{ 'is-danger': $v.entry.application.required_specialty.$error }"
              :message="$v.entry.application.required_specialty.$error ? $t('required') : ''"
            >
              <b-input
                v-if="isDraft"
                v-model="entry.application.required_specialty"
                type="textarea"
                :disabled="!canEdit"
                name="required_specialty"
              />
              <div v-else>
                <label class="label">{{ $t('cv.requiredSpecialty.label') }}</label>
                <div
                  v-if="entry.application.required_specialty"
                  class="content box has-padding"
                  v-html="entry.application.required_specialty"
                ></div>
                <div v-else class="instructions">{{ $t('noContent') }}</div>
              </div>

              <template #message>
                <p v-if="isDraft && $v.entry.application.required_specialty.$error" class="help is-danger">
                  {{ $t('required') }}
                </p>

                <p class="help is-meta">
                  {{ $t('cv.requiredSpecialty.message') }}
                </p>
              </template>
            </b-field>
          </div>

          <h3 class="has-margin-bottom-small has-margin-top-medium">{{ $t('cv.criteria') }}</h3>
          <p class="help is-meta has-margin-bottom-medium">
            {{ $t('cv.criteriaMessage') }}
          </p>

          <div>
            <h4 class="has-margin-top-medium">{{ $t('cv.scientificPerformance') }}</h4>
            <HtmlEditor
              v-if="isDraft"
              :input.sync="entry.application.sp_production_impact"
              :show-menu-bar="isDraft && canEdit"
              :label="$t('cv.spProductionImpact')"
              :editable="isDraft && canEdit"
              :min-height="'11rem'"
              :type="{ 'is-danger': $v.entry.application.sp_production_impact.$error }"
              :message="$v.entry.application.sp_production_impact.$error ? $t('required') : ''"
              class="has-margin-top"
            ></HtmlEditor>

            <div v-else class="has-margin-top">
              <label class="label">{{ $t('cv.spProductionImpact') }}</label>
              <div
                v-if="entry.application.sp_production_impact"
                class="content box has-padding"
                v-html="entry.application.sp_production_impact"
              ></div>
              <div v-else class="instructions">{{ $t('noContent') }}</div>
            </div>

            <HtmlEditor
              v-if="isDraft"
              :input.sync="entry.application.sp_future"
              :show-menu-bar="isDraft && canEdit"
              :label="$t('cv.spFuture')"
              :editable="isDraft && canEdit"
              :min-height="'11rem'"
              :type="{ 'is-danger': $v.entry.application.sp_future.$error }"
              :message="$v.entry.application.sp_future.$error ? $t('required') : ''"
              class="has-margin-top-medium"
            ></HtmlEditor>
            <div v-else class="has-margin-top">
              <label class="label">{{ $t('cv.spFuture') }}</label>
              <div
                v-if="entry.application.sp_future"
                class="content box has-padding"
                v-html="entry.application.sp_future"
              ></div>
              <div v-else class="instructions">{{ $t('noContent') }}</div>
            </div>

            <HtmlEditor
              v-if="isDraft"
              :input.sync="entry.application.sp_coordination"
              :show-menu-bar="isDraft && canEdit"
              :label="$t('cv.spCoordination')"
              :editable="isDraft && canEdit"
              :min-height="'11rem'"
              :type="{ 'is-danger': $v.entry.application.sp_coordination.$error }"
              :message="$v.entry.application.sp_coordination.$error ? $t('required') : ''"
              class="has-margin-top-medium"
            ></HtmlEditor>
            <div v-else class="has-margin-top">
              <label class="label">{{ $t('cv.spCoordination') }}</label>
              <div
                v-if="entry.application.sp_coordination"
                class="content box has-padding"
                v-html="entry.application.sp_coordination"
              ></div>
              <div v-else class="instructions">{{ $t('noContent') }}</div>
            </div>

            <HtmlEditor
              v-if="isDraft"
              :input.sync="entry.application.sp_intervention"
              :show-menu-bar="isDraft && canEdit"
              :label="$t('cv.spIntervention')"
              :editable="isDraft && canEdit"
              :min-height="'11rem'"
              :type="{ 'is-danger': $v.entry.application.sp_intervention.$error }"
              :message="$v.entry.application.sp_intervention.$error ? $t('required') : ''"
              class="has-margin-top-medium"
            ></HtmlEditor>
            <div v-else class="has-margin-top">
              <label class="label">{{ $t('cv.spIntervention') }}</label>
              <div
                v-if="entry.application.sp_intervention"
                class="content box has-padding"
                v-html="entry.application.sp_intervention"
              ></div>
              <div v-else class="instructions">{{ $t('noContent') }}</div>
            </div>

            <h4 class="has-margin-top-medium">{{ $t('cv.pedagogicalCapacity') }}</h4>

            <HtmlEditor
              v-if="isDraft"
              :input.sync="entry.application.pc_class"
              :show-menu-bar="isDraft && canEdit"
              :label="$t('cv.pcClass')"
              :editable="isDraft && canEdit"
              :min-height="'11rem'"
              :type="{ 'is-danger': $v.entry.application.pc_class.$error }"
              :message="$v.entry.application.pc_class.$error ? $t('required') : ''"
              class="has-margin-top"
            ></HtmlEditor>

            <div v-else class="has-margin-top">
              <label class="label">{{ $t('cv.pcClass') }}</label>
              <div
                v-if="entry.application.pc_class"
                class="content box has-padding"
                v-html="entry.application.pc_class"
              ></div>
              <div v-else class="instructions">{{ $t('noContent') }}</div>
            </div>

            <HtmlEditor
              v-if="isDraft"
              :input.sync="entry.application.pc_material_orientation"
              :show-menu-bar="isDraft && canEdit"
              :label="$t('cv.pcMaterialOrientation')"
              :editable="isDraft && canEdit"
              :min-height="'11rem'"
              :type="{ 'is-danger': $v.entry.application.pc_material_orientation.$error }"
              :message="$v.entry.application.pc_material_orientation.$error ? $t('required') : ''"
              class="has-margin-top-medium"
            ></HtmlEditor>
            <div v-else class="has-margin-top">
              <label class="label">{{ $t('cv.pcMaterialOrientation') }}</label>
              <div
                v-if="entry.application.pc_material_orientation"
                class="content box has-padding"
                v-html="entry.application.pc_material_orientation"
              ></div>
              <div v-else class="instructions">{{ $t('noContent') }}</div>
            </div>

            <HtmlEditor
              v-if="isDraft"
              :input.sync="entry.application.pc_intervention"
              :show-menu-bar="isDraft && canEdit"
              :label="$t('cv.pcIntervention')"
              :editable="isDraft && canEdit"
              :min-height="'11rem'"
              :type="{ 'is-danger': $v.entry.application.pc_intervention.$error }"
              :message="$v.entry.application.pc_intervention.$error ? $t('required') : ''"
              class="has-margin-top-medium"
            ></HtmlEditor>

            <div v-else class="has-margin-top">
              <label class="label">{{ $t('cv.pcIntervention') }}</label>
              <div
                v-if="entry.application.pc_intervention"
                class="content box has-padding"
                v-html="entry.application.pc_intervention"
              ></div>
              <div v-else class="instructions">{{ $t('noContent') }}</div>
            </div>

            <template v-if="entry.procedure.extra_strand_title">
              <h4 class="has-margin-top-medium">{{ $t('cv.extraStrand') }}</h4>
              <HtmlEditor
                v-if="isDraft"
                :input.sync="entry.application.extra_strand"
                :show-menu-bar="isDraft && canEdit"
                :label="entry.procedure.extra_strand_title"
                :editable="isDraft && canEdit"
                :type="{ 'is-danger': $v.entry.application.extra_strand.$error }"
                :message="$v.entry.application.extra_strand.$error ? $t('required') : ''"
                class="has-margin-top"
              ></HtmlEditor>
              <div v-else>
                <label class="label">{{ entry.procedure.extra_strand_title }}</label>
                <div
                  v-if="entry.application.extra_strand"
                  class="content box has-padding"
                  v-html="entry.application.extra_strand"
                ></div>
                <div v-else class="instructions">{{ $t('noContent') }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div id="c" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('career') }}
              <faicon v-if="isCareerPlanReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <div>
            <p v-html="$t('careerFile')"></p>
            <div class="files has-margin-top">
              <p v-if="!getFilesByType('career_plan').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </p>
              <RecordFileEntry
                v-for="file in getFilesByType('career_plan')"
                :key="file.id"
                :file="file"
                :can-edit="canEdit"
                :allow-classified="false"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>

            <Uploader
              v-if="canEdit && !getFilesByType('career_plan').length"
              reference-id="uploadCarrerPlan"
              allowed="pdf"
              :clear-after="true"
              :limit="1"
              :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/career_plan'"
              @upload="updateFilesCart"
            />
          </div>

          <HtmlEditor
            v-if="isDraft"
            :input.sync="entry.application.career_plan"
            :show-menu-bar="isDraft && canEdit"
            :label="$t('careerPlan')"
            :editable="isDraft && canEdit"
            class="has-margin-top-medium"
          >
            <template #message>
              <p class="help is-meta">
                {{ $t('careerPlanMessage') }}
              </p>
            </template>
          </HtmlEditor>
          <div v-else-if="isEntryLoaded" class="has-margin-top has-margin-bottom">
            <label class="label">{{ $t('careerPlan') }}</label>
            <div
              v-if="entry.application.career_plan"
              class="content box has-padding"
              v-html="entry.application.career_plan"
            ></div>
            <div v-else class="instructions">{{ $t('noContent') }}</div>
          </div>
        </div>
      </div>

      <div id="d" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('certificates.title') }}
              <faicon v-if="isCertificatesReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="entry.application" class="panel-body">
          <b-field :label="$t('certificates.phdDate')">
            <b-datepicker v-model="phdDate" :disabled="!isDraft || !canEdit" placeholder="Data" icon="calendar-plus">
            </b-datepicker>
          </b-field>
          <b-field :label="$t('certificates.phdCountry')">
            <b-select v-model="entry.application.phd_international" :disabled="!isDraft || !canEdit">
              <option :value="false">
                {{ $t('national') }}
              </option>
              <option :value="true">
                {{ $t('international') }}
              </option>
            </b-select>
          </b-field>

          <div
            v-if="isEntryLoaded && entry.application.phd_international"
            class="has-margin-top-medium box has-padding"
          >
            <p class="is-size-3">{{ $t('certificates.phdInternational') }}</p>
            <b-field
              v-if="entry.application.phd_international"
              :label="$t('certificates.phdInternationalDate')"
              class="has-margin-top"
            >
              <b-datepicker
                v-model="phdInternationalDate"
                :disabled="!isDraft || !canEdit"
                placeholder="Data"
                icon="calendar-plus"
                :date-formatter="dateFormatter"
              >
              </b-datepicker>
            </b-field>

            <b-field
              v-if="isEntryLoaded && entry.application.phd_international && isDraft"
              :label="$t('additionalInformation')"
            >
              <div class="html-editor">
                <editor-menu-bar
                  v-if="isDraft && canEdit"
                  v-slot="{ commands, isActive, focused }"
                  :editor="editorPhDRegistry"
                >
                  <div class="menubar" :class="{ 'is-focused': focused }">
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <icon name="bold" />
                    </button>
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <icon name="italic" />
                    </button>
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <icon name="underline" />
                    </button>
                    <button
                      class="button is-text is-small"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph"
                    >
                      <icon name="paragraph" />
                    </button>
                    <button class="button is-text is-small" @click="commands.undo">
                      <icon name="undo" />
                    </button>
                    <button class="button is-text is-small" @click="commands.redo">
                      <icon name="redo" />
                    </button>
                  </div>
                </editor-menu-bar>
                <editor-content :editor="editorPhDRegistry" />
              </div>
            </b-field>
            <div v-else class="has-margin-top">
              <div
                v-if="isEntryLoaded && entry.application.phd_international_notes"
                class="content"
                v-html="entry.application.phd_international_notes"
              ></div>
              <div v-else class="content" v-html="entry.application.phd_international_notes"></div>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <p class="has-text-weight-bold has-margin-top-medium has-margin-bottom">{{ $t('certificates.copies') }}</p>
          <b-field v-if="isEntryLoaded">
            <b-switch
              v-model="entry.application.qualifications_certificate"
              :disabled="!canEdit"
              @input="updateSwitch('qualifications_certificate')"
            >
              {{ $t('certificates.copiesDeclaration') }}
            </b-switch>
          </b-field>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-if="!loading && !entry.application.qualifications_certificate" class="panel-body">
          <p v-if="isDraft && canEdit">
            <span v-html="$t('certificates.files')"></span>
            <strong v-if="isEntryLoaded && entry.application.phd_international">
              {{ $t('certificates.phdInternationalProof') }}</strong
            >
          </p>

          <div class="files has-margin-top">
            <p v-if="!getFilesByType('certificate').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('certificate')"
              :key="file.id"
              :file="file"
              :can-edit="canEdit"
              :allow-classified="false"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit"
            reference-id="uploadCertificate"
            allowed="pdf"
            :clear-after="true"
            :limit="0"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/certificate'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="e" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('relevantWork.title') }}
              <faicon v-if="isRelevantWorksReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <p v-if="isDraft && canEdit" class="has-margin-bottom" v-html="$t('relevantWork.description')"></p>

          <div v-if="!withRelevantWork" class="instructions">
            {{ $t('entry.empty') }}
          </div>
          <div v-else class="has-margin-bottom">
            <div
              v-for="record in entry.application.relevant_work"
              :key="record.$index"
              class="record has-background-light has-padding-small"
            >
              <div class="is-flex-grow">
                <div class="is-flex is-flex-align-center is-flex-space-between">
                  <div class="record-title">
                    {{ record.description }}
                  </div>
                  <div v-if="canEdit" class="record-nav has-margin-left">
                    <b-button
                      size="is-small"
                      type="is-danger"
                      outlined
                      @click="removeListEntry(record.key, 'relevant_work')"
                    >
                      {{ $t('actions.delete') }}
                    </b-button>
                  </div>
                </div>
                <div>
                  <div v-if="record.file_key && record.file" class="has-text-small">
                    <RecordFileEntry :key="record.file.id" :file="record.file" @download="downloadFile" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="addRelevantWorkActive && canEdit"
            class="has-background-light has-padding-medium is-rounded has-margin-bottom"
          >
            <h3 class="has-text-primary has-margin-bottom">{{ $t('entry.new') }}</h3>

            <div v-if="newRelevantWorkEntry">
              <b-field
                :label="$t('relevantWork.descriptionLabel')"
                :type="{ 'is-danger': newRelevantWorkEntry.$errors.description }"
                :message="newRelevantWorkEntry.$errors.description"
              >
                <b-input v-model="newRelevantWorkEntry.description" type="textarea" name="description" />
              </b-field>

              <div class="panel-header-title is-flex is-flex-align-center is-flex-space-between">
                <label class="label" v-html="$t('relevantWork.file')"></label>
              </div>

              <RecordFileEntry
                v-if="newRelevantWorkEntry.file"
                :key="newRelevantWorkEntry.file.id"
                allowed="pdf"
                :file="newRelevantWorkEntry.file"
                :can-edit="canEdit"
                @remove="removeRelevantWorkFile"
                @download="downloadFile"
              />

              <Uploader
                v-if="!newRelevantWorkEntry.file"
                reference-id="uploadRelevantWork"
                allowed="pdf"
                :clear-after="true"
                :limit="1"
                :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/relevant_work'"
                @upload="updateRelevantWorkFiles"
              />
              <p v-if="isDraft && newRelevantWorkEntry.$errors.file_key" class="help is-danger">
                {{ $t('required') }}
              </p>
            </div>

            <div class="is-flex is-flex-justify-right has-margin-top">
              <b-button type="is-light" class="has-margin-right" @click="addRelevantWorkActive = false">
                {{ $t('actions.cancel') }}
              </b-button>
              <b-button type="is-primary" :loading="loadingNewItem" outlined @click="addRelevantWork">
                {{ $t('actions.add') }}
              </b-button>
            </div>
          </div>

          <div v-if="!addRelevantWorkActive && canEdit" class="has-margin-top has-margin-bottom-medium">
            <b-button type="is-text" outlined icon-left="plus" @click="addRelevantWorkActive = true">
              {{ $t('entry.add') }}
            </b-button>
          </div>
        </div>

        <div v-if="isEntryLoaded && getFilesByType('more_relevant').length">
          <h4>{{ $t('evidenceCopies') }}</h4>
          <div v-if="!loading" class="panel-body">
            <div v-if="isDraft && canEdit" v-html="$t('relevantWork.files')"></div>
            <div class="files has-margin-top has-margin-bottom-medium">
              <p v-if="!getFilesByType('more_relevant').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </p>
              <RecordFileEntry
                v-for="file in getFilesByType('more_relevant')"
                :key="file.id"
                allowed="pdf"
                :file="file"
                :can-edit="canEdit"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="f" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('otherWorks') }} <faicon v-if="isOtherCvWorksReady" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <p v-if="isDraft && canEdit" v-html="$t('otherWorksDescription')"></p>
          <div class="files has-margin-top">
            <p v-if="!getFilesByType('other_cv').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('other_cv')"
              :key="file.id"
              :file="file"
              :can-edit="canEdit"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit"
            reference-id="uploadOtherCv"
            :clear-after="true"
            :limit="0"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/other_cv'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="merit_justification" class="panel">
        <div class="panel-header">
          <h2>{{ $t('meritJustification.title') }}</h2>
        </div>
        <div class="panel-content">
          <HtmlEditor
            v-if="isDraft"
            :input.sync="entry.application.merit_justification"
            :show-menu-bar="isDraft && canEdit"
            :editable="isDraft && canEdit"
          >
            <template #message>
              <p class="help is-meta has-margin-bottom-medium">
                {{ $t('meritJustification.message') }}
              </p>
            </template>
          </HtmlEditor>
          <div v-else-if="isEntryLoaded" class="has-margin-top">
            <div
              v-if="entry.application.merit_justification"
              class="content box has-padding"
              v-html="entry.application.merit_justification"
            ></div>
            <div v-else class="instructions">{{ $t('noContent') }}</div>
          </div>
        </div>
      </div>

      <div id="i" class="panel">
        <div class="panel-header">
          <h2>
            {{ $t('acceptance') }} <faicon v-if="isAcceptenceReady" class="active" icon="check-circle" size="xs" />
          </h2>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_requirements"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_requirements')"
            >
              {{ $t('acceptPublicAdmin') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_native_language"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_native_language')"
            >
              {{ $t('acceptNativeSpeaker') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_documents_authenticity"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_documents_authenticity')"
            >
              {{ $t('acceptanceDocumentsAuthenticity') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.accept_notifications"
              :disabled="!canEdit"
              @input="doSaveApplication(true)"
            >
              {{ $t('acceptNotifications112') }}
            </b-switch>
          </b-field>
        </div>
      </div>

      <div id="g" class="panel">
        <div class="panel-header">
          <h2>{{ $t('extraFiles') }}</h2>
          <b-tag size="is-small">{{ $t('optional') }}</b-tag>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <p v-if="isDraft && canEdit" v-html="$t('userNotesFiles')"></p>

          <div class="files has-margin-top">
            <p v-if="!getFilesByType('other').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </p>
            <RecordFileEntry
              v-for="file in getFilesByType('other')"
              :key="file.id"
              :file="file"
              :can-edit="canEdit"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>

          <Uploader
            v-if="canEdit"
            reference-id="uploadOther"
            :clear-after="true"
            :limit="0"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/other'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <div id="h" class="panel">
        <div class="panel-header">
          <h2>{{ $t('teleconference') }}</h2>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field>
            <b-switch
              v-model="entry.application.remote_interview"
              :disabled="!canEdit"
              @input="updateSwitch('remote_interview')"
            >
              {{ $t('acceptTeleconference') }}
            </b-switch>
          </b-field>
        </div>
      </div>

      <div id="i" class="panel">
        <div class="panel-header">
          <h2>{{ $t('additionalInformation') }}</h2>
          <b-tag size="is-small">{{ $t('optional') }}</b-tag>
        </div>
        <div class="panel-content">
          <b-field v-if="isDraft" :label="$t('otherInformation')" :message="$t('atLeast50Chars')">
            <div class="html-editor">
              <editor-menu-bar v-if="isDraft && canEdit" v-slot="{ commands, isActive, focused }" :editor="editor">
                <div class="menubar" :class="{ 'is-focused': focused }">
                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.bold() }"
                    @click="commands.bold"
                  >
                    <icon name="bold" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.italic() }"
                    @click="commands.italic"
                  >
                    <icon name="italic" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.underline() }"
                    @click="commands.underline"
                  >
                    <icon name="underline" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.paragraph() }"
                    @click="commands.paragraph"
                  >
                    <icon name="paragraph" />
                  </button>

                  <button class="button is-text is-small" @click="commands.undo">
                    <icon name="undo" />
                  </button>

                  <button class="button is-text is-small" @click="commands.redo">
                    <icon name="redo" />
                  </button>
                </div>
              </editor-menu-bar>
              <editor-content :editor="editor" />
            </div>
          </b-field>
          <div v-if="!isDraft && isEntryLoaded" class="has-margin-top">
            <div
              v-if="entry.application.user_notes"
              class="content box has-padding"
              v-html="entry.application.user_notes"
            ></div>
            <div v-else class="instructions">{{ $t('noContent') }}</div>
          </div>
        </div>
      </div>

      <div v-if="canSubmit || isDraft || canEdit">
        <hr />
        <b-field grouped>
          <b-field v-if="canSubmit && isDraft && canEdit" class="has-margin-bottom">
            <b-button type="is-primary" outlined @click="doSubmitApplication">{{ $t('actions.submit') }}</b-button>
          </b-field>
          <b-field expanded>
            <b-button
              v-if="!isSubmitted && !isCancelled && canEdit"
              type="is-text"
              :loading="loadingSave"
              @click="doSaveApplication"
              >{{ $t('actions.save') }}</b-button
            >
          </b-field>
          <b-field>
            <b-button v-if="canOpen" type="is-text" @click="doReopen">{{ $t('actions.reopen') }}</b-button>
          </b-field>
          <b-field>
            <b-button v-if="!canOpen && canEdit" class="has-text-dark" type="is-text" @click="doCancelApplication">{{
              $t('actions.cancel')
            }}</b-button>
          </b-field>
        </b-field>
      </div>
    </template>

    <template v-slot:sidebar>
      <ContentLoader v-if="loading" type="sidebar" />
      <div v-else>
        <div class="has-margin-bottom">
          <div class="label is-marginless">{{ $t('status') }}</div>
          <div
            class="is-size-1 has-text-weight-bold"
            :class="{
              'has-text-primary': entry.application.status == 'submitted',
            }"
          >
            {{ getItemStatusDescription(entry.application.status) }}
          </div>
        </div>

        <div v-if="canEdit || canCancel || canOpen" class="label">{{ $t('operations') }}</div>

        <b-field v-if="isDraft" class="has-margin-bottom">
          <b-button
            expanded
            size="is-medium"
            :disabled="!canSubmit && canEdit"
            :type="{
              'is-outlined': !canSubmit && canEdit,
              'is-primary': canSubmit && canEdit,
            }"
            @click="doSubmitApplication"
            >{{ $t('actions.submit') }}</b-button
          >
        </b-field>

        <b-field v-if="canEdit || canCancel || canOpen" grouped class="has-margin-bottom has-margin-top">
          <b-field expanded>
            <b-button v-if="canOpen" type="is-black" expanded :loading="loadingSave" @click="doReopen">{{
              $t('actions.reopen')
            }}</b-button>
            <b-button
              v-if="!isSubmitted && !isCancelled && canEdit"
              :type="{
                'is-outlined': canSubmit,
                'is-primary': !canSubmit,
              }"
              expanded
              :loading="loadingSave"
              @click="doSaveApplication"
              >{{ $t('actions.save') }}</b-button
            >
          </b-field>
          <b-field>
            <b-button outlined @click="doCancelApplication">{{ $t('actions.cancel') }}</b-button>
          </b-field>
        </b-field>

        <hr />

        <div>
          <h4 class="has-margin-bottom">{{ $t('support.needHelp') }}?</h4>
          <button class="button is-small" @click="goToSupport">
            <faicon icon="concierge-bell"></faicon> {{ $t('support.ticket.new') }}
          </button>
        </div>

        <div class="has-margin-top-large">
          <p class="is-meta has-margin-bottom">{{ $t('procedure.details') }}</p>
          <p>
            <router-link :to="'/procedure/' + entry.procedure.code">
              <faicon icon="hashtag" class="has-text-small"></faicon>
              {{ entry.procedure.code }}
            </router-link>
          </p>
          <p class="has-margin-top">{{ procedureTitle }}</p>
          <p class="has-text-small">
            <span v-if="entry.procedure.area">Área disciplinar de {{ entry.procedure.area }}</span>
            <span v-if="entry.procedure.sub_area">, subárea de {{ entry.procedure.sub_area }}</span
            >.
          </p>
          <p class="has-text-small is-meta has-margin-top">
            <span v-if="entry.procedure.department_title">{{ entry.procedure.department_title }} / </span>
            <span v-if="entry.procedure.faculty_title">{{ entry.procedure.faculty_title }}</span>
          </p>
        </div>
      </div>
    </template>

    <template v-slot:modals>
      <ModalFirstApplication :show.sync="isFirstApplicationModalActive" />
      <ModalAfterSubmit :show.sync="isAfterSubmitModalActive" />
      <ModalReadyToSubmit :show.sync="isReadyToSubmitModalActive" />
      <ModalDownloading :show.sync="isDownloadingFile" :percentage.sync="$store.state.isDownloading" />
    </template>
  </MainLayout>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import { required } from 'vuelidate/lib/validators'
import { minLength, maxLength, numeric, requiredIf, helpers } from 'vuelidate/lib/validators'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, Italic, Link, Underline, History, Placeholder } from 'tiptap-extensions'

import ApplicationService from '@/services/ApplicationService'
import ProcedureService from '@/services/ProcedureService'
import UtilitiesService from '@/services/UtilitiesService'

import MainLayout from '@/components/layouts/MainLayout'

import Uploader from '@/components/utilities/Uploader'
import ContentLoader from '@/components/utilities/ContentLoader'
import RecordFileEntry from '@/components/records/RecordFileEntry'
import ModalAfterSubmit from '@/components/modals/ModalAfterSubmit'
import ModalReadyToSubmit from '@/components/modals/ModalReadyToSubmit'
import ModalDownloading from '@/components/modals/ModalDownloading'
import ModalFirstApplication from '@/components/modals/ModalFirstApplication'
import CountriesSelect from '@/components/utilities/CountriesSelect'
import PhoneCountriesSelect from '@/components/utilities/PhoneCountriesSelect'
import HtmlEditor from '@/components/utilities/HtmlEditor'

const minIf = helpers.withParams({ type: 'minIf' }, value => {
  return !helpers.req(value) || value.length > 8
})

export default {
  components: {
    HtmlEditor,
    MainLayout,
    Uploader,
    ContentLoader,
    RecordFileEntry,
    EditorContent,
    EditorMenuBar,
    CountriesSelect,
    PhoneCountriesSelect,
    ModalAfterSubmit,
    ModalReadyToSubmit,
    ModalFirstApplication,
    ModalDownloading,
  },

  data() {
    return {
      loading: false,
      loadingSave: false,

      user: this.$store.getters.getUser,

      entry: {},

      // Editor para notas adicionais (opcional)
      editor: new Editor({
        editable: this.isDraft && this.canEdit,
        extensions: [
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.$t('writeText'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.entry.application.user_notes = getHTML()
        },
      }),

      // Editor para notas sobre o doutoramento (caso internacional)
      editorPhDRegistry: new Editor({
        editable: this.isDraft && this.canEdit,
        extensions: [
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.$t('writeText'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.entry.application.phd_international_notes = getHTML()
        },
      }),

      isFirstApplicationModalActive: false,
      isAfterSubmitModalActive: false,
      isReadyToSubmitModalActive: false,

      phdDate: new Date(),
      phdInternationalDate: new Date(),

      addRelevantWorkActive: false,
      newRelevantWorkEntry: {
        description: null,
        file_key: null,
        $errors: {},
      },
      addContractualRelationshipsActive: false,
      newContractualRelationshipsEntry: {
        start_date: null,
        end_date: null,
        category: '',
        areas: '',
        nature_of_the_bond: '',
        education_institution: '',
        $errors: {},
      },
      loadingNewItem: false,

      defaultData: {
        relevantWorkEntry: {
          description: null,
          file_key: null,
          $errors: {},
        },
        contractualRelationshipsEntry: {
          start_date: null,
          end_date: null,
          category: '',
          areas: '',
          nature_of_the_bond: '',
          education_institution: '',
          $errors: {},
        },
      },
    }
  },

  computed: {
    isDownloadingFile() {
      return this.$store.state.isDownloading > 0 && this.$store.state.isDownloading < 100
    },
    // Testes do estado da candidatura
    isEntryLoaded() {
      return this.entry && this.entry.application
    },

    procedureCode() {
      return this.$route.params.procedureKey
    },

    applicationStatus() {
      return ApplicationService.helpers.getStatusMap(this.entry.application.status)
    },

    procedureTitle() {
      return ProcedureService.helpers.getProcedureTitle(this.entry.procedure)
    },

    isDraft() {
      return this.entry && this.entry.application && this.entry.application.status == 'draft'
    },

    isSubmitted() {
      return this.entry && this.entry.application && this.entry.application.status == 'submitted'
    },

    isCancelled() {
      return this.entry && this.entry.application && this.entry.application.status == 'cancelled'
    },

    canEdit() {
      return this.entry && this.entry.permissions && this.entry.permissions.edit
    },

    canSubmit() {
      return (
        this.isEntryLoaded &&
        this.entry.permissions &&
        this.entry.permissions.submit &&
        this.isPersonalDataReady &&
        this.isCvReady &&
        this.isCareerPlanReady &&
        this.isCertificatesReady &&
        this.isRelevantWorksReady &&
        this.isAcceptenceReady &&
        this.isMeritJustificationReady
      )
    },

    canCancel() {
      return this.entry.permissions.change_status
    },

    canOpen() {
      return (
        this.entry &&
        this.entry.application &&
        this.entry.application.status != 'draft' &&
        this.entry.permissions.change_status
      )
    },

    // Testes de validação
    isPersonalDataReady() {
      if (!isEmpty(this.entry)) {
        return Boolean(
          this.entry.application.address_line1 &&
            !isEmpty(this.entry.application.country) &&
            this.entry.application.locality &&
            this.entry.application.postal_code &&
            this.entry.application.name &&
            !isEmpty(this.entry.application.phone_country) &&
            this.entry.application.phone_number &&
            this.getFilesByType('personal_data').length
        )
      }
      return false
    },

    isCvReady() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('cv')
        return (
          files &&
          files.length == 1 &&
          this.entry?.application?.required_specialty &&
          this.entry?.application?.contractual_relationships?.length
        )
      }
      return false
    },

    isCareerPlanReady() {
      if (!isEmpty(this.entry)) {
        // const files = this.getFilesByType('career_plan')
        // return files && files.length == 1

        return this.entry.application.career_plan && this.entry.application.career_plan.length > 1
      }
      return false
    },

    isCertificatesReady() {
      if (!isEmpty(this.entry)) {
        if (!this.entry.application.phd_date) {
          return false
        } else if (this.entry.application.phd_international && !this.entry.application.phd_international_date) {
          return false
        }
        // Valida ficheiros
        const files = this.getFilesByType('certificate')
        if (!this.entry.application.qualifications_certificate) {
          return Boolean(files && files.length)
        } else {
          return true
        }
      }
      return false
    },

    isRelevantWorksReady() {
      if (!isEmpty(this.entry)) {
        // const files = this.getFilesByType('more_relevant')
        // return files && files.length >= 3
        return this.entry.application.relevant_work.length == 5
      }
      return false
    },

    isOtherCvWorksReady() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('other_cv')
        return files && files.length
      }
      return false
    },

    isMeritJustificationReady() {
      if (!isEmpty(this.entry)) {
        return this.entry.application.merit_justification && this.entry.application.merit_justification.length > 1
      }
      return false
    },

    isRelevantWorksOpenToUpload() {
      if (!isEmpty(this.entry)) {
        const files = this.getFilesByType('more_relevant')
        return files && files.length < 6
      }
      return false
    },

    isAcceptenceReady() {
      if (!isEmpty(this.entry)) {
        return Boolean(
          this.entry.application.acceptance_requirements &&
            this.entry.application.acceptance_documents_authenticity &&
            this.entry.application.accept_notifications
        )
      }
      return false
    },

    withRelevantWork() {
      return (
        this.entry.application && this.entry.application.relevant_work && this.entry.application.relevant_work.length
      )
    },

    withContractualRelationships() {
      return (
        this.entry.application &&
        this.entry.application.contractual_relationships &&
        this.entry.application.contractual_relationships.length
      )
    },
  },

  created() {
    this.showFirstApplicationModal()
  },

  beforeDestroy() {
    this.editor.destroy()
    this.editorPhDRegistry.destroy()
  },

  beforeMount() {
    this.getApplication()
  },

  // Valida o formulário de dados pessoais
  validations() {
    return {
      entry: {
        application: {
          name: {
            required,
            min: minLength(2),
          },
          address_line1: {
            required,
            min: minLength(2),
          },
          postal_code: {
            required,
          },
          locality: {
            required,
          },
          country: {
            required,
          },
          phone_country: {
            required,
          },
          phone_number: {
            required,
            numeric,
          },

          sp_production_impact: { required, min: minLength(2), max: maxLength(65000) },
          sp_future: { required, min: minLength(2), max: maxLength(65000) },
          sp_coordination: { required, min: minLength(2), max: maxLength(65000) },
          sp_intervention: { required, min: minLength(2), max: maxLength(65000) },

          pc_class: { required, min: minLength(2), max: maxLength(65000) },
          pc_material_orientation: { required, min: minLength(2), max: maxLength(65000) },
          pc_intervention: { required, min: minLength(2), max: maxLength(65000) },

          required_specialty: { required, min: minLength(2), max: maxLength(65000) },

          extra_strand: {
            required: requiredIf(function() {
              return Boolean(this.entry.procedure.extra_strand_title)
            }),
            max: maxLength(65000),
            minIf,
          },
        },
      },
    }
  },

  methods: {
    getItemStatusDescription(status) {
      const map = {
        draft: this.$t('draft'),
        cancelled: this.$t('cancelled'),
        submitted: this.$t('submitted'),
      }
      return map[status]
    },

    dateFormatter(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },

    updateCountry(value) {
      this.entry.application.country = value
    },

    updatePhoneCountry(value) {
      this.entry.application.phone_country = value
    },

    getDefaultEntry(key) {
      return Object.assign({}, this.defaultData[key])
    },

    async addRelevantWork() {
      await this.addToList('relevant_work', 'newRelevantWorkEntry', 'addRelevantWorkActive')
    },

    async addContractualRelationships() {
      await this.addToList(
        'contractual_relationships',
        'newContractualRelationshipsEntry',
        'addContractualRelationshipsActive'
      )
    },

    updateRelevantWorkFiles(files) {
      if (!files || !files.length || !this.newRelevantWorkEntry) {
        return
      }
      const file = files[0].response.data.file
      this.entry.application.files.push(file)
      this.newRelevantWorkEntry.file_key = file.key
      this.newRelevantWorkEntry.file = file
    },

    removeRelevantWorkFile(file) {
      if (!file || !this.newRelevantWorkEntry) {
        return
      }

      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          const fileDeleted = await ApplicationService.deleteFile(file.key)
          if (fileDeleted) {
            this.newRelevantWorkEntry.file_key = null
            this.newRelevantWorkEntry.file = null
            this.entry.application.files = this.entry.application.files.filter(el => el.key !== file.key)
          }
        },
      })
    },

    async removeListEntry(entryKey, listKey) {
      const newItems = await ApplicationService.deleteListEntry(this.entry.application.code, listKey, entryKey)

      if (listKey === 'relevant_work') {
        let parsedItems = []
        for (const item of newItems) {
          let file = null
          if (item.file_key) {
            file = this.entry?.application?.files
              ? this.entry.application.files.find(el => el.type === 'relevant_work' && el.key === item.file_key)
              : null
          }
          parsedItems.push({ ...item, file })
        }

        this.entry.application[listKey] = parsedItems
      } else {
        this.entry.application[listKey] = newItems
      }
    },

    async addToList(type, attribute, formBoxActive = null) {
      this.loadingNewItem = true

      try {
        const entry = { ...this[attribute] }

        // Parse dates
        if (entry.start_date) entry.start_date = this.dateFormatter(entry.start_date)
        if (entry.end_date) entry.end_date = this.dateFormatter(entry.end_date)

        // Save, get fresh data and reset
        const { items, errors } = await ApplicationService.addListEntry(this.entry.application.code, type, entry)

        if (errors) {
          const newErrors = {}
          for (const errorItem of errors) newErrors[errorItem.field] = errorItem.message
          this[attribute].$errors = newErrors
        } else {
          if (formBoxActive) this[formBoxActive] = false

          if (type === 'relevant_work') {
            let parsedItems = []
            for (const item of items) {
              let file = null
              if (item.file_key) {
                file = this.entry?.application?.files
                  ? this.entry.application.files.find(el => el.type === 'relevant_work' && el.key === item.file_key)
                  : null
              }
              parsedItems.push({ ...item, file })
            }

            this.entry.application[type] = parsedItems
          } else {
            this.entry.application[type] = items
          }

          this[attribute] = this.getDefaultEntry('relevantWorkEntry')
        }
      } finally {
        this.loadingNewItem = false
      }
    },

    // Obtém o ojecto da candidatura
    async getApplication() {
      this.loading = true

      if (this.$route.params.action == 'start') {
        this.entry = await ApplicationService.add(this.$route.params.procedureKey)
        this.$router.replace({ ...this.$router.currentRoute, params: { action: 'view' } })
      }

      this.entry = await ApplicationService.get(this.$route.params.procedureKey)

      // Popula o nome com os dados do perfil e define o país default como Portugal
      if (!this.entry.application.name) {
        this.entry.application.name = this.user.full_name || ''
      }

      // Converte das datas
      if (this.entry.application.phd_date) {
        this.phdDate = dayjs(this.entry.application.phd_date).toDate()
      }
      if (this.entry.application.phd_international_date) {
        this.phdInternationalDate = dayjs(this.entry.application.phd_international_date).toDate()
      }

      // Popula os editores de texto com o conteúdos definido pelo utilizador
      this.editor.setContent(this.entry.application.user_notes)
      this.editorPhDRegistry.setContent(this.entry.application.phd_international_notes)

      if (this.entry.application.relevant_work && this.entry.application.relevant_work.length) {
        const parsedWork = []
        for (const relevant_work of this.entry.application.relevant_work) {
          let file = null
          if (relevant_work.file_key) {
            file = this.entry?.application?.files
              ? this.entry.application.files.find(
                  el => el.type === 'relevant_work' && el.key === relevant_work.file_key
                )
              : null
          }
          parsedWork.push({ ...relevant_work, file })
        }

        this.entry.application.relevant_work = parsedWork
      }
      this.loading = false
    },

    updateApplicationData(data) {
      this.entry = data
      if (this.entry.application.relevant_work && this.entry.application.relevant_work.length) {
        const parsedWork = []
        for (const relevant_work of this.entry.application.relevant_work) {
          let file = null
          if (relevant_work.file_key) {
            file = this.entry?.application?.files
              ? this.entry.application.files.find(
                  el => el.type === 'relevant_work' && el.key === relevant_work.file_key
                )
              : null
          }
          parsedWork.push({ ...relevant_work, file })
        }

        this.entry.application.relevant_work = parsedWork
      }
    },

    // Envia a candidatura para o backend e utiliza o novo objeto no dom
    doSaveApplication(silenceNotification = false) {
      this.loadingSave = true

      this.$v.$touch()

      // Até encontrar forma melhor...
      this.entry.application.phd_date = dayjs(this.phdDate).format('YYYY-MM-DD')
      this.entry.application.phd_international_date = dayjs(this.phdInternationalDate).format('YYYY-MM-DD')

      ApplicationService.update(this.entry.application)
        .then(response => {
          this.updateApplicationData(response.data)
          this.loadingSave = false

          // Apresenta modal caso esteja pronto para submeter (primeira vez)
          // Valida se o utilizador já viu a modal de primeira submissão neste procedimento especifico
          if (this.canSubmit) {
            const optionKey = 'modal-ready-to-submit-' + this.entry.application.code
            UtilitiesService.getOption(optionKey).then(response => {
              if (!response.data) {
                UtilitiesService.setOption(optionKey, true).then(() => {
                  setTimeout(() => {
                    this.isReadyToSubmitModalActive = true
                  }, 500)
                })
              }
            })
          }

          if (!silenceNotification) {
            this.$buefy.snackbar.open({
              message: this.$t('applicationState.saved'),
              type: 'is-primary',
              position: 'is-bottom-right',
              duration: 2000,
              queue: false,
            })
          }
        })
        .catch(err => {
          this.alertErrorCallEnded(err.response.status)
        })
    },

    doCancelApplication() {
      this.$buefy.dialog.confirm({
        title: this.$t('applicationState.cancel'),
        message: this.$t('applicationState.cancelConfirm'),
        confirmText: this.$t('applicationState.cancelYes'),
        cancelText: this.$t('applicationState.cancelNo'),
        onConfirm: async () => {
          this.entry.application.status = 'cancelled'

          ApplicationService.update(this.entry.application)
            .then(response => {
              this.updateApplicationData(response.data)
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    doReopen() {
      this.$buefy.dialog.confirm({
        title: this.$t('applicationState.reopen'),
        message: this.$t('applicationState.reopenConfirm'),
        confirmText: this.$t('actions.reopen'),
        type: 'is-black',
        onConfirm: async () => {
          this.entry.application.status = 'draft'

          ApplicationService.update(this.entry.application)
            .then(response => {
              this.updateApplicationData(response.data)
              this.$buefy.notification.open({
                type: 'is-black',
                message: this.$t('applicationState.reopenDone'),
                duration: 2000,
              })
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    doSubmitApplication() {
      this.$buefy.dialog.confirm({
        size: 'is-large',
        cancelText: this.$t('actions.cancel'),
        title: this.$t('applicationState.submit'),
        message: this.$t('applicationState.submitConfirm'),
        onConfirm: async () => {
          // Cria novo objeto para garantir que é apenas considerado se válido no backend
          const updatedApplication = cloneDeep(this.entry.application)
          updatedApplication.status = 'submitted'

          ApplicationService.update(updatedApplication)
            .then(response => {
              this.updateApplicationData(response.data)
              this.showAfterSubmitModal()
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    // Atualiza todas as opções para simplificar
    async updateSwitch() {
      this.doSaveApplication(true)
    },

    // Gestão de ficheiros
    async downloadFile(file) {
      await ApplicationService.downloadFile(file.key, file.filename)
    },

    removeFile(file) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        onConfirm: async () => {
          const fileDeleted = await ApplicationService.deleteFile(file.key)
          if (fileDeleted) {
            this.doSaveApplication(true)
          }
        },
      })
    },

    updateFilesCart() {
      this.doSaveApplication(true)
    },

    getFilesByType(type) {
      if (!this.entry.application.files) {
        return
      }
      return this.entry.application.files.filter(file => {
        return file.type == type
      })
    },

    goToSupport() {
      if (this.isOpen && this.canEdit) {
        this.$buefy.dialog.confirm({
          title: this.$t('saveBeforeLeave'),
          message: this.$t('saveBeforeTicket'),
          cancelText: this.$t('actions.cancel'),
          confirmText: this.$t('saveLeave'),
          type: 'is-primary',
          onConfirm: () => {
            this.doSaveApplication(true)
            this.$router.push({ path: '/support', query: { procedureCode: this.entry.procedure.code, openNew: true } })
          },
        })
      } else {
        this.$router.push({ path: '/support', query: { procedureCode: this.entry.procedure.code, openNew: true } })
      }
    },

    // Modais
    showAfterSubmitModal() {
      this.isAfterSubmitModalActive = true
    },

    showFirstApplicationModal() {
      const optionIntro = 'first-application-v1'
      UtilitiesService.getOption(optionIntro).then(response => {
        if (!response.data) {
          UtilitiesService.setOption(optionIntro, true)
          setTimeout(() => {
            this.isFirstApplicationModalActive = true
          }, 2000)
        }
      })
    },

    alertErrorCallEnded(status) {
      // Em caso de erro, obter novamente a candidatura do lado da api
      this.getApplication()

      if (status == 403) {
        this.$buefy.dialog.alert({
          title: this.$t('applicationState.closed'),
          message: this.$t('applicationState.closedDescription'),
          type: 'is-black',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }

      // Ocorreu algo inesperado
      else {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: this.$t('applicationState.internalError'),
          duration: 5000,
        })
      }
    },
  },
}
</script>
