<template>
  <div class="record is-entry">
    <div class="is-flex">
      <div class="record-icon">
        <faicon :icon="getIcon()" />
      </div>
      <div class="record-content">
        <div class="is-flex">
          <div class="is-flex-grow is-flex record-title">
            <div v-if="!inputOpened" class="is-flex filename" @click="openEdit">
              <v-clamp autoresize :max-lines="1" @click="$emit('download', file)">{{
                file.title || file.filename
              }}</v-clamp>
              <b-button v-if="canEdit" class="has-margin-left" type="is-text" size="is-small"
                ><faicon icon="edit"></faicon
              ></b-button>
            </div>
            <form v-else class="is-flex" @submit.prevent="updateFile" @keyup.enter="updateFile">
              <b-input :id="'input-title-' + file.key" v-model="file.title" autofocus class="is-flex-grow"></b-input>
              <b-button type="is-text" class="has-margin-left" size="is-small" @click="updateFile">{{
                $t('actions.save')
              }}</b-button>
              <b-button type="is-text" class="has-text-black" size="is-small" @click="inputOpened = false">{{
                $t('actions.cancel')
              }}</b-button>
            </form>
          </div>
          <div v-if="canEdit && !inputOpened">
            <b-button type="is-danger" outlined size="is-small" @click="$emit('remove', file)">{{
              $t('actions.delete')
            }}</b-button>
          </div>
        </div>
        <div class="has-text-muted is-flex is-flex-align-bottom">
          <a class="has-margin-right" @click="$emit('download', file)">Download</a>
          <span class="has-text-weight-medium has-margin-right has-text-tiny">{{ file.size | formatNumber }}</span>
          <span v-if="file.title" class="is-hidden-mobile has-margin-right has-text-tiny">
            <v-clamp autoresize :max-lines="1">{{ file.filename }}</v-clamp>
          </span>
          <span class="has-text-tiny has-text-muted">{{ file.created_date | formatDateTime }}</span>
        </div>
        <div v-if="canEdit && allowClassified" class="has-margin-top is-flex">
          <b-switch v-model="file.reserved" :disabled="!canEdit" size="is-small" @input="updateFile">
            {{ $t('cv.classified') }}</b-switch
          >
          <a class="has-text-small has-margin-left" @click="showHelpFileReserve"
            ><faicon icon="info-circle" class="is-hidden-mobile"></faicon> {{ $t('knowMore') }}</a
          >
        </div>
        <b-tag v-else-if="!canEdit && file.reserved" type="is-black" class="has-margin-top-small" size="is-small">{{
          $t('classified')
        }}</b-tag>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationService from '@/services/ApplicationService'

export default {
  props: {
    file: Object,
    canEdit: Boolean,
    allowClassified: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      inputOpened: false,
    }
  },

  methods: {
    showHelpFileReserve() {
      this.$buefy.dialog.alert({
        title: this.$t('cv.classified'),
        message: `Escolha esta opção caso o ficheiro seja documento classificado, que revele segredo comercial
        ou industrial, ou segredo relativo à propriedade literária, artística ou científica, sob pena do mesmo
        ser livremente acedido por qualquer um dos demais candidatos, em sede de consulta de processo.`,
      })
    },
    openEdit() {
      if (this.canEdit) {
        this.inputOpened = true
        this.$nextTick(() => {
          document.getElementById('input-title-' + this.file.key).focus()
        })
      }
    },
    async updateFile() {
      try {
        await ApplicationService.updateFile(this.file)
        this.inputOpened = false
      } catch (error) {
        this.$buefy.snackbar.open({
          message: this.$t('user.photoErrUpload'),
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }
    },
    getIcon() {
      const extension = this.$options.filters.getExtension(this.file.filename)
      const map = {
        pdf: 'file-pdf',
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
      }
      if (!(extension in map)) {
        return 'file-alt'
      }
      return map[extension]
    },
  },
}
</script>
