<template>
  <div>
    <b-modal :active.sync="isActive" :width="500" class="modal-hero background-primary">
      <div class="panel">
        <div class="panel-body">
          <h2 class="lead active">A sua candidatura está pronta a ser submetida!</h2>
          <p class="has-margin-top">
            Todos os requisitos foram preenchidos. Para submeter, basta carregar no botão que está agora visivel no
            canto superior direito da sua página de candidatura.
          </p>
        </div>
        <div class="panel-footer has-text-right">
          <b-button type="is-black" outlined @click="isActive = false">{{ $t('actions.next') }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      isActive: this.show || false,
      slide: 0,
      arrow: false,
      indicator: true,
      indicatorInside: true,
      indicatorStyle: 'is-lines',
    }
  },
  watch: {
    show(value) {
      this.isActive = value
    },
  },
}
</script>
